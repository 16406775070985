<template>
  <section class="checklist">
    <section class="checklist-step"  v-for="(step, index) in checklist" :key="index" :class="{
      isCompleted: step.isCompleted,
      current: step.current,
    }">
      <div class="checklist-step status" :class="{'heaven': isHeaven, 'greenn': !isHeaven}">
        <img src="@/assets/img/icons/mini-check-white.svg" v-if="step.isCompleted" />
      </div>
      <section class="checklist-step infos">
        <p v-if="step.title">{{ step.title }}</p>
        <p v-if="step.description">{{ step.description }}</p>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ProductChecklist',
  props: {
    checklist: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  }
}
</script>

<style lang="scss" scoped>
.checklist {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;

  &-step {
    display: flex;
    gap: 30px;
    height: 110px;

    .status {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid transparent;
    }

    .infos {
      display: flex;
      flex-direction: column;
      gap: 5px;

      :first-child {
        color: #9a9a9a;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      :last-child {
        color: #9a9a9a;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &.isCompleted {
      .greenn {
        background: $base-color;

        img {
          height: 50%;
          width: 50%;
        }
      }
      .heaven {
        background: $base-color-heaven !important;
        
        img {
          height: 50%;
          width: 50%;
        }
      }
    }

    &.current {
      .greenn {
        border: 4px solid $base-color;
      }
      .heaven {
        border: 4px solid $base-color-heaven !important;
      }
      .infos {
        :first-child {
          color: #333;
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    &:not(.isCompleted, .current) {
      .status {
        border: 3px solid #e5e5e5;
      }

      .infos {
        :first-child {
          color: #333;
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &:not(:last-child) {
      .status {
        &::after {
          content: "";
          position: absolute;
          top: 36px;
          width: 2px;
          height: 70px;
          background: #e5e5e5;
        }
      }
    }
  }
}
</style>
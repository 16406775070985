<template>
  <list-item
    v-if="!loading"
    :title="$t('seller.products.entrega_arquivos.text_1896')"
    :list="list"
    @delete="removeFile"
  />
  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import Vue from "vue";

// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";

// helper:
import { EventBus } from "@/main.js";
import { datetime } from "@/filters/date.js";
// services:
import FileService from "@/services/resources/FileService";
const serviceFiles = FileService.build();
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  name: "ListagemArquivos",
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      showList: true,
      response_webhook: {},
      url_edit: null,
      maxLength: 48,
      client: {
        width: 0,
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
    lista: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    isMobile() {
      if (this.client.width <= 667) this.maxLength = 30;
      return this.client.width <= 667;
    },
    list() {
      return this.lista.map((item) => ({
        id: item.id,
        icon: "file-icon.svg",
        name: `#${item.id} - ${item.name}`,
        description: `${this.$t(
          "seller.products.listagem_arquivos.text_499"
        )} ${datetime(item.created_at)}`,
        actions: ["delete"],
      }));
    },
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    // remove Callback
    async removeFile(payload) {
      const { id } = payload;
      const file = this.lista.filter((item) => item.id === id).pop();
      const { name } = file;

      Vue.swal({
        title: this.$t("seller.products.listagem_arquivos.text_1943"),
        text: `${this.$t(
          "seller.products.listagem_arquivos.text_501"
        )} ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t(
          "seller.products.listagem_arquivos.text_1943"
        ),
        cancelButtonText: this.$t(
          "seller.products.listagem_arquivos.text_1944"
        ),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = {
            id: id,
          };

          await serviceFiles
            .destroy(data)
            .then(async(response) => {
              if (response) {
                this.lista = this.lista.filter((item) => item.id !== id);
                await this.$store.commit(
                  "setAttachments",
                  this.lista
                );

                this.$bvToast.toast(
                  this.$t("seller.products.listagem_arquivos.text_502"),
                  {
                    title: this.$t(
                      "seller.products.listagem_arquivos.text_502"
                    ),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );

                if (!this.lista.length) {
                  await this.atualizaRegra();
                }
                EventBus.$emit("integracoesLista", -1);
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_arquivos.text_503"),
                  {
                    title: this.$t(
                      "seller.products.listagem_arquivos.text_503"
                    ),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    async atualizaRegra() {
      let data = {
        product_id: this.id_product,
        type: "file",
        url: "list",
      };

      await serviceIntegration
        .search(data)
        .then(async(response) => {
          await this.removeRegra(response[0].id);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async removeRegra(id) {
      let data = {
        id: id,
      };
      await serviceIntegration.destroy(data);
    },
  },
  mounted() {
    EventBus.$emit("integracoesLista", this.lista.length);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

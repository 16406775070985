<template>
  <BaseModal
    name="modal-infusionsoft"
    size="lg"
    :title="$t('seller.products.infusionsoft.text_443')"
    @shown="openModal"
    @hidden="closeModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Infusion Soft</h4>
          <div class="d-flex mb-2" data-anima="top">
            <b-col cols="12" data-anima="top">
              <BaseButton
                v-if="
                  gerenciar === false &&
                  !newConnection &&
                  selectedConection === null
                "
                @click="newConnection = true"
                variant="black"
                class=""
                >
                  {{ $t('seller.products.infusionsoft.text_1927') }}
                </BaseButton>
              <b-form-input
                v-if="newConnection"
                class="mb-3"
                v-model="nameConnection"
                :placeholder="$t('seller.products.infusionsoft.text_445')"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <div class="d-flex">
                <BaseButton
                  variant="link-info"
                  class="gerencia-conexoes mt-3 mr-3"
                  @click="newConnection = false"
                  v-if="gerenciar === false && newConnection"
                >
                  {{ $t('seller.products.infusionsoft.text_1928') }}
                </BaseButton>
                <BaseButton
                  variant="black"
                  class="gerencia-conexoes mt-3"
                  @click="openWindowInfusionsoft"
                  v-if="gerenciar === false && newConnection"
                  >{{ $t('seller.products.infusionsoft.text_1929') }}</BaseButton
                >
              </div>
              <iframe
                v-if="openIntFrame"
                height="0"
                width="0"
                style="display: none"
                :src="srcIframe"
              ></iframe>
            </b-col>
          </div>
          <div
            v-if="infusionsoft.length === 0 && gerenciar === false"
            data-anima="top"
          >
            <p>
              {{ $t('seller.products.infusionsoft.text_446') }}
            </p>
            <p>
              {{ $t('seller.products.infusionsoft.text_447') }}
            </p>
            <br />
            <p>{{ $t('seller.products.infusionsoft.text_448') }}</p>
          </div>
          <b-col
            cols="12"
            data-anima="top"
            v-if="infusionsoft.length !== 0 && !newConnection"
          >
            <ul class="mt-3" v-if="selectedConection === null">
              <li
                v-for="item in infusionsoft"
                :key="item.title + item.id"
                data-anima="bottom"
              >
                <div @click="selectItem(item.id)">
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ item.id }} - {{ item.title }}
                      <p data-anima="bottom" class="ativo">
                        {{$t('seller.products.infusionsoft.text_449')}} {{ moment(item.created_at).locale('pt-br').format('DD/MM/YYYY [às] HH:mm') }}
                      </p>
                    </span>
                  </div>
                </div>
                <div class="acoes-webhook">
                  <img
                    src="@/assets/img/icons/lixeira.svg"
                    class="icon-lixeira"
                    :id="'btn-remover-' + item.id"
                    @click="removeConection(item.id)"
                  />
                  <b-tooltip
                    :target="'btn-remover-' + item.id"
                    :title="$t('seller.products.infusionsoft.text_1930')"
                    placement="top"
                  />
                </div>
              </li>
            </ul>

            <ul v-else>
              <li data-anima="bottom">
                <div>
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ selectedConection }} -
                      {{ filterItem(selectedConection)[0].title }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col
            cols="12"
            class="mt-4"
            data-anima="top"
            v-if="selectedConection"
          >
            <h6 class="titulo-acao" v-if="selectedTypeAction !== null">
              {{ $t('seller.products.infusionsoft.text_450') }}
            </h6>
            <b-form novalidate>
            <h4 class="title-inter">{{ $t('seller.products.infusionsoft.text_450') }}</h4>
            <div class="grid-events">
              <b-form-group label="Vendas" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  v-for="(op, index) in events.options_sales"
                  :name="`check-${index}`"
                  :id="`check-${index}`"
                  v-model="selectEvent[index]"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <div
                    v-show="op.value == 'saleRefused' && selectEvent[1]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesRefused"
                      class="check-box mb-2"
                      :name="`check-refunsed-${index}`"
                      :id="`check-refunsed-${index}`"
                      :key="sub.value"
                      v-model="selectEventRefunsed[index]"
                      @change="subTriggerChanged(sub.value, 'saleRefused')"
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                  <div
                    v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesWaiting"
                      class="check-box mb-2"
                      :name="`check-waiting-${index}`"
                      :id="`check-waiting-${index}`"
                      :key="sub.value"
                      v-model="selectEventWaiting[index]"
                      @change="
                        subTriggerChanged(sub.value, 'saleWaitingPayment')
                      "
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group :label="$t('seller.products.infusionsoft.text_451')" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label="Checkout" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-form>
          </b-col>
          <span>
            <b-col
              cols="12"
              v-if="
                infusionsoft.length !== 0 &&
                selectedConection !== null &&
                makePlanilha === false
              "
            >
              <b-form-group
                class="p-2"
                :label="$t('seller.products.infusionsoft.text_452')"
                label-for="form_list_active"
              >
                <multiselect
                  v-model="lista"
                  :options="lista_options"
                  :value="lista"
                  :multiple="true"
                  :close-on-select="false"
                  :clearOnSelect="true"
                  :preserve-search="true"
                  :placeholder="$t('seller.products.infusionsoft.text_452')"
                  label="name"
                  track-by="name"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  id="multi_select_list_active"
                  name="list_active"
                  :custom-label="customLabel"
                  :class="{'heaven': isHeaven}"
                >
                  <template v-slot:afterList>
                    <div v-observe-visibility="reachedEndOfList" />
                  </template>
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('list_active')">
                  {{ $t('seller.products.infusionsoft.text_453') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </span>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('client.text_51') }}
      </BaseButton>
      <BaseButton
        v-if="
          gerenciar === false &&
          infusionsoft.length !== 0 &&
          selectedConection !== null
        "
        variant="black"
        :disabled="loading"
        @click="createCallback"
      >
        {{ $t('seller.products.infusionsoft.text_1931') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Conexoes from "../../MyAccount/modals/Conexoes";
import Cookies from "js-cookie";
// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

import GoogleSheetsService from "@/services/resources/GoogleSheetsService";
const serviceGoogleSheets = GoogleSheetsService.build();

import InfusionsoftService from "@/services/resources/InfusionsoftService";
const  infusionsoftService= InfusionsoftService.build();

import Multiselect from "vue-multiselect";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build()
import moment from 'moment';
import gtagSend from '@/utils/gtagSend';

export default {
  data() {
    return {
      moment: moment,
      selectedTypeAction: null,
      makePlanilha: false,
      newPlanilha: "",
      nameConnection: "",
      newConnection: false,
      optionsConection: [{ id: null, name: this.$t('seller.products.infusionsoft.text_1932') }],
      selectedConection: null,
      openIntFrame: false,
      srcIframe: "",
      loading: false,
      submit: false,
      infusionsoft: [],
      connection_name: null,
      connection_api_key: null,
      connection_base_url: null,
      lista_options: [],
      lista_options_prev: null,
      lista_options_next: null,
      select_callback: "infusionSoft",
      trigger: [],
      callback: [],
      lista: null,
      gerenciar: false,
      multiselect: true,
      selectEvent: [],
      selectEventRefunsed: [],
      selectEventWaiting: [],
      events: {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        url: "",
        callback: "infusionSoft",
        options_sales: [
          {
            value: "salePaid",
            text: this.$t('seller.products.options_sales.text_751'),
          },
          {
            value: "saleRefused",
            text: this.$t('seller.products.options_sales.text_752'),
          },
          {
            value: "saleRefunded",
            text: this.$t('seller.products.options_sales.text_753'),
          },
          {
            value: "saleChargedback",
            text: this.$t('seller.products.options_sales.text_754'),
          },
          {
            value: "saleUnpaid",
            text: this.$t('seller.products.options_sales.text_755'),
          },
          {
            value: "saleWaitingPayment",
            text: this.$t('seller.products.options_sales.text_756'),
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: this.$t('seller.products.options_saleswaiting.text_757'),
          },
          {
            value: "BOLETO",
            text: this.$t('seller.products.options_saleswaiting.text_758'),
          },
          {
            value: "PIX",
            text: this.$t('seller.products.options_saleswaiting.text_759'),
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: this.$t('seller.products.options_salesrefused.text_760'),
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: this.$t('seller.products.options_salesrefused.text_761'),
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: this.$t('seller.products.options_salesrefused.text_762'),
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: this.$t('seller.products.options_salesrefused.text_763'),
          },
          {
            value: "INVALID_DATA",
            text: this.$t('seller.products.options_salesrefused.text_764'),
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: this.$t('seller.products.options_salesrefused.text_765'),
          },
          {
            value: "INVALID_CVV",
            text: this.$t('seller.products.options_salesrefused.text_766'),
          },
          {
            value: "BANK",
            text: this.$t('seller.products.options_salesrefused.text_767'),
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: this.$t('seller.products.options_salesrefused.text_768'),
          },
          {
            value: "INVALID_INSTALLMENTS",
            text: this.$t('seller.products.options_salesrefused.text_769'),
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: this.$t('seller.products.options_salesrefused.text_770'),
          },
          {
            value: "SUSPECTED_FRAUD",
            text: this.$t('seller.products.options_salesrefused.text_771'),
          },
          {
            value: "GENERIC",
            text: this.$t('seller.products.options_salesrefused.text_772'),
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: this.$t('seller.products.options_sub.text_773'),
          },
          {
            value: "contractPaid",
            text: this.$t('seller.products.options_sub.text_774'),
          },
          {
            value: "contractTrialing",
            text: this.$t('seller.products.options_sub.text_775'),
          },
          {
            value: "contractPendingPayment",
            text: this.$t('seller.products.options_sub.text_776'),
          },
          {
            value: "contractEnded",
            text: this.$t('seller.products.options_sub.text_777'),
          },
          {
            value: "contractCanceled",
            text: this.$t('seller.products.options_sub.text_778'),
          },
        ],
        options_checkout: [
          {
            value: "checkoutAbandoned",
            text: this.$t('seller.products.options_checkout.text_779'),
          }
        ]
      }
    }
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Conexoes,
    Multiselect,
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    deletePlanilha() {
      this.loading = true;

      let data = {
        connection_id: this.selectedConection,
        id_file: this.lista.id,
      };

      serviceGoogleSheets
        .destroyAll(data)
        .then(() => {
          this.lista = null
          this.fetchLists()
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false
        })
    },
    filterItem(id, type) {
      let myArray = this.optionsConection;
      return myArray.filter((x) => x.id === id);
    },
    selectItem(id) {
      this.selectedConection = id;
      this.lista_options = [];
      this.fetchLists('first');
    },
    removeConection(id) {
      this.loading = true;
      serviceConnection
        .destroy(id)
        .then(() => {
          this.fetchConnection()
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false
        })
    },
    openWindowInfusionsoft() {

      const cleanWhiteSpaces = (text) => {
        let result = '';
        text.split('').map(letter => letter !== ' ' ? result=result+letter : result=result+'-');
        return result;
      }

      const host = `${this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE}${this.$route.fullPath}/${cleanWhiteSpaces(this.nameConnection)}`
      // const fake = `http://localhost:8089${this.$route.fullPath}/${cleanWhiteSpaces(this.nameConnection)}`
      
      location.replace(`
        https://accounts.infusionsoft.com/app/oauth/authorize?response_type=code&redirect_uri=${host}&client_id=${process.env.VUE_KEAP_CLIENT_ID}
      `)

      setTimeout(() => {
        // this.openIntFrame = true;
        this.newConnection = false;
        this.intervalConnections();
      }, 3000);
    },
    intervalConnections() {
      var that = this;
      this.intervalid1 = setInterval(function () {
        that.fetchConnection();
      }, 5000);
    },
    resetInputs() {
      this.infusionsoft = [];
      this.sub_trigger2 = [];
      this.connection_name = null;
      this.connection_api_key = null;
      this.connection_base_url = null;
      this.lista_options = [];
      this.tag_options = [];
      this.tag = null;
      this.openIntFrame = false;
      this.selectEvent = [];
      this.selectEventRefunsed = [];
      this.selectEventWaiting = [];
      // this.options_callback[0].type = true;
      // this.options_callback[1].type = false;
      this.select_callback = "infusionSoft";
      this.trigger = [];
      this.lista = null;
      this.gerenciar = false;
    },
    createInfusionsoft(infusionsoft) {
      let data = infusionsoft;
      serviceCallback
        .create(data)
        .then(() => {
          gtagSend('integracao_adicionada_produto', {
              ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
              ID_produto: this.id_product,
              titulo_do_produto:this.$store.getters.productName,
              tipo_de_integração: 'infusionSoft'
          });
          this.submit = false;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // adicionar uma nova callback
    createCallback() {
      return new Promise((resolve) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            if (this.lista !== null) {
              let data = {
                product_id: this.id_product,
                tag: this.lista,
                trigger: this.connection_api_key,
                callback: "infusionSoft",
                type: "infusionsoft",
                connection_id: this.selectedConection,
              };

              if (this.trigger.length) {
                this.trigger.forEach(async (event) => {
                  data.trigger = "";
                  data.trigger = event;
                  if (event === 'saleRefused' && this.selectEventRefunsed.length) {
                    this.selectEventRefunsed.map(async (item, index) => {
                      if (item) {
                        if(index == 0) {
                          this.events.options_salesRefused.map(async (subtrigger, i) => {
                            if (i != 0) {
                              let sub_trigger = this.events.options_salesRefused[i].value
                              await this.postCallback({ ...data, sub_trigger  }, this.events.options_salesRefused[i].text);
                            }
                          })
                        } else {
                          let sub_trigger = this.events.options_salesRefused[index].value
                          await this.postCallback({ ...data, sub_trigger  }, this.events.options_salesRefused[index].text);
                        }
                      }
                    })
                  } else if(event === 'saleWaitingPayment' && this.selectEventWaiting.length){
                    this.selectEventWaiting.map(async (item, index) => {
                      if (item) {
                        if(index == 0) {
                          this.events.options_salesWaiting.map(async (subtrigger, i) => {
                            if (i != 0) {
                              let sub_trigger2 = this.events.options_salesWaiting[i].value
                              await this.postCallback({ ...data, sub_trigger2  }, this.events.options_salesWaiting[i].text);
                            }
                          })
                        } else {
                          let sub_trigger2 = this.events.options_salesWaiting[index].value
                          await this.postCallback({ ...data, sub_trigger2  }, this.events.options_salesWaiting[index].text);
                        }
                      }
                    })
                  }else {
                    await this.postCallback(data, event);
                  }
                  resolve(true);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(this.$t('seller.products.infusionsoft.text_1933'), {
                  title: "Infusion Soft",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                resolve(false);
              }
            } else {
              this.loading = false;
              this.$bvToast.toast(this.$t('seller.products.infusionsoft.text_454'), {
                title: "Infusion Soft",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },
    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${this.convertTrigger(event)}] ${this.$t('seller.products.infusionsoft.text_1934')}`,
            {
              title: "Infusion Soft",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide("modal-infusionsoft");
          this.resetInputs();
          setTimeout(() => {
            this.$emit("close");
          }, 500);
          EventBus.$emit("reloadInfusionSoftIntegration");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // checkbox dos eventos
    triggerChanged(v) {
      if (this.trigger.includes(v)) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "saleRefused") {
        const include = this.events.sub_trigger.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventRefunsed = [];
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      }else{

        const include = this.events.sub_trigger2.includes(v);
  
        if (v == "TODOS") {
          if (!include) {
            this.selectEventWaiting = [];
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          }
        }
  
        if (include) {
          this.events.sub_trigger2.splice(this.events.sub_trigger2.indexOf(v), 1);
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    customLabelTags({ tag }) {
      return `${tag}`;
    },
    customLabel({ name }) {
      return `${name}`;
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.fetchLists(this.lista_options_next);
      }
    },
    fetchLists(page) {
      let data = {
        url: '/tags',
        connection_id: this.selectedConection,
        type: "infusionsoft",
        limit: 6
      };

      if (page && page !== "first") {
        data.page = page;
      }

      infusionsoftService
        .search(data)
        .then((response) => {
          if (page && page !== "first") {
            this.lista_options.push(response.tags);
          } else {
            this.lista_options = response.tags;
          }
          // if (page === "first") {
          //   this.lista = response.tags;
          // }

          this.lista_options_next = response.nextPageToken;
          this.lista_options_prev = response.prevPageToken;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchConnection() {
      this.optionsConection = [];
      serviceConnection
        .search({ connection_id: this.selectedConection, type: "infusionsoft"  })
        .then((response) => {
          response.forEach((element) => {
            if(element.type === 'infusionsoft') {
              this.optionsConection.push({
                id: element.id,
                title: element.title,
                created_at: element.created_at
              });
            }
          });
          this.infusionsoft = this.optionsConection;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do infusionsoft
      this.fetchConnection();
    },
    closeModal() {
      clearInterval(this.intervalid1);
      this.selectedConection = null;
      this.resetInputs();
      this.optionsConection = [{ id: null, name: this.$t('seller.products.infusionsoft.text_1932') }];
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid": return this.$t('seller.products.options_sales.text_751')
        case "saleRefused": return this.$t('seller.products.options_sales.text_752')
        case "saleRefunded": return this.$t('seller.products.options_sales.text_753')
        case "BOLETO": return this.$t('seller.products.options_saleswaiting.text_758')
        case "PIX": return this.$t('seller.products.options_saleswaiting.text_759')
        case "saleChargedback": return this.$t('seller.products.options_sales.text_754')
        case "saleUnpaid": return this.$t('seller.products.options_sales.text_755')
        case "saleWaitingPayment": return this.$t('seller.products.options_sales.text_756')

        //  assinaturas
        case "contractUnpaid": return this.$t('seller.products.options_sub.text_773')
        case "contractPaid": return this.$t('seller.products.options_sub.text_774')
        case "contractTrialing": return this.$t('seller.products.options_sub.text_775')
        case "contractPendingPayment": return this.$t('seller.products.options_sub.text_776')
        case "contractEnded": return this.$t('seller.products.options_sub.text_777')

        //  checkout
        case "checkoutAbandoned": return this.$t('seller.products.options_checkout.text_779')

        //  venda recusada
        case "INVALID_CREDIT_CARD": return this.$t('seller.products.options_salesrefused.text_761')
        case "INSUFFICIENT_FUNDS": return this.$t('seller.products.options_salesrefused.text_762')
        case "INVALID_PAYMENT_TYPE": return this.$t('seller.products.options_salesrefused.text_763')
        case "INVALID_DATA": return this.$t('seller.products.options_salesrefused.text_764')
        case "INVALID_CLIENT_DATA": return this.$t('seller.products.options_salesrefused.text_765')
        case "INVALID_CVV": return this.$t('seller.products.options_salesrefused.text_766')
        case "BANK": return this.$t('seller.products.options_salesrefused.text_767')
        case "CREDIT_CARD_OPERATOR": return this.$t('seller.products.options_salesrefused.text_768')
        case "INVALID_INSTALLMENTS": return this.$t('seller.products.options_salesrefused.text_769')
        case "CURRENCY_NOT_SUPPORTED": return this.$t('seller.products.options_salesrefused.text_770')
        case "SUSPECTED_FRAUD": return this.$t('seller.products.options_salesrefused.text_771')
        case "GENERIC": return this.$t('seller.products.options_salesrefused.text_772')
        default: return trigger
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.infusion-btn {
  width: 200px;
  height: 42px;
  cursor: pointer;
  background-color: #148d24;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.infusion-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.infusion-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.infusion-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.infusion-btn .btn-text b {
  color: #fff !important;
}
.infusion-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.infusion-btn:active {
  background: #1669F2;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 40px 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top a {
  font-size: 14px;
  color: #81858e;
}
.lista-entrega h5 {
  color: #2133d2;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li,
  .opcoes-entrega li {
    display: block;
    height: 100px;
  }
  .acoes-webhook {
    margin-left: 30px;
    margin-top: 7px;
  }
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img.icon-file {
  width: 18px;
  margin-right: 30px;
}
.lista-entrega ul li div img + img {
  margin-left: 20px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p,
.info-lista {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 300px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}

.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li:hover,
  .opcoes-entrega li:hover {
    font-weight: 500;
    background: #f7f7f7;
  }
}

.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}

/* edição web */
.info-list {
  position: relative;
}
.info-list span {
  font-size: 14px;
}
.info-list p.ativo {
  text-transform: initial;
  font-size: 12px;
  margin-top: 3px;
  color: #81858e;
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 400px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid #81858e !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 667px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}

/* 
 */
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
  /* padding: 10px; */
}
.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.info-qtd {
  margin-top: -15px;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>

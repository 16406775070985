<template>
  <BaseModal
    name="modal-memberkit"
    size="lg"
    :title="$t('seller.products.memberkit.text_542')"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• MemberKit</h4>
          <div class="d-flex mb-4">
            <BaseButton
              variant="black"
              class="gerencia-conexoes"
              @click="openConexoes"
              >{{ $t('seller.products.memberkit.text_544') }}</BaseButton
            >
          </div>
          <b-form v-if="memberkit.options_conexao.length" data-vv-scope="memberkit">
            <b-row>
              <!-- Connection -->
              <b-col class="mr-3">
                <b-form-group :label="$t('seller.products.memberkit.text_545')" label-for="conexao">
                  <b-form-select
                    v-model="memberkit.data.connection_id"
                    :options="memberkit.options_conexao"
                    name="conexao"
                    id="conexao"
                    :placeholder="$t('seller.products.memberkit.text_546')"
                    v-validate="'required'"
                    @change="changeConnection"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('memberkit.conexao')">
                    {{ $t('seller.products.memberkit.text_546') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Type -->
              <b-col>
                <b-form-group :label="$t('seller.products.memberkit.text_558')" label-for="tipo">
                  <b-form-select
                    v-model="memberkit.data.access"
                    :options="memberkit.options_conexao_tipo"
                    name="tipo"
                    id="tipo"
                    :placeholder="$t('seller.products.memberkit.text_559')"
                    v-validate="'required'"
                    @change="changeMemberKit"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('memberkit.tipo')">
                    {{ $t('seller.products.memberkit.text_559') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Select Subscription -->
            <b-form-group
              :label="$t('seller.products.memberkit.text_547')"
              label-for="classroom"
              v-if="
                this.memberkit.data.access == 'classroom' &&
                this.memberkit.options.length
              "
              data-anima="top"
            >
              <multiselect
                v-model="memberkit.data.classroom_ids"
                :options="memberkit.options"
                :value="memberkit.data.classroom_ids"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('seller.products.memberkit.text_548')"
                label="text"
                track-by="text"
                selectLabel=""
                deselectLabel=""
                selectedLabel="✔️"
                class="multi_select_memberkit"
                id="multi_select_memberkit"
                name="classroom"
                v-validate="'required'"
              >
              </multiselect>
              <b-form-invalid-feedback :state="!errors.has('memberkit.classroom')">
                {{ $t('seller.products.memberkit.text_549') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('seller.products.memberkit.text_550')"
              label-for="membership_id"
              v-if="
                this.memberkit.data.access == 'subscription' &&
                this.memberkit.options.length
              "
              data-anima="top"
            >
              <b-form-select
                v-model="memberkit.data.membership_id"
                :options="memberkit.options"
                name="membership_id"
                id="membership_id"
                :placeholder="$t('seller.products.memberkit.text_550')"
                v-validate="'required'"
              >
              </b-form-select>
              <b-form-invalid-feedback
                :state="!errors.has('memberkit.data.membership_id')"
              >
                {{ $t('seller.products.memberkit.text_551') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <div v-else>
            <p>{{ $t('seller.products.memberkit.text_552') }}</p>
            <p>{{ $t('seller.products.memberkit.text_553') }}</p>
            <br />
            <p>{{ $t('seller.products.memberkit.text_554') }}</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes 
      connection-description="Integração com o Memberkit" 
      connection-type="memberkit" 
      @close="fetchConnections" 
    />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.memberkit.text_560') }}
      </BaseButton>
      <BaseButton
        v-if="memberkit.options_conexao.length"
        variant="black"
        :disabled="loading || !memberkit.options.length"
        @click="adicionarMemberKit"
      >
        {{ $t('seller.products.memberkit.text_555') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Multiselect from 'vue-multiselect'
import Conexoes from '../../MyAccount/modals/Conexoes'

import ConnectionService from '@/services/resources/ConnectionService'
import IntegrationService from '@/services/resources/IntegrationService'

const serviceConnection = ConnectionService.build()
const serviceIntegration = IntegrationService.build()

export default {
  name: 'Memberkit',
  components: { Multiselect, Conexoes },
  props: {
    id_product: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      submit: false,
      memberkit: {
        data: {
          access: null,
          type: "memberkit",
          product_id: null,
          connection_id: null
        },
        options_conexao_tipo: [
          { value: "subscription", text: "Assinatura" },
          { value: "classroom", text: "Turmas" }
        ],
        options_conexao: [],
        options: []
      }
    }
  },
  methods: {
    // abrir modal de conexoes
    openConexoes() {
      this.$bvModal.show("modal-conexoes");
    },
    // adicionar um novo item no memberkit
   async adicionarMemberKit() {
      this.submit = true;
      this.loading = true;
      this.memberkit.data.product_id = this.id_product;
      // verificando se é assinatura
      if (this.memberkit.data.access == "subscription") {
        this.memberkit.data.membership_name = document.querySelector("#membership_id option:checked").text;
      }
      // caso nao seja assinatura
      else {
        this.memberkit.data.classroom_names = [
          ...this.memberkit.data.classroom_ids,
        ].map((item) => {
          return item.text;
        });
        this.memberkit.data.classroom_ids = [
          ...this.memberkit.data.classroom_ids,
        ].map((item) => {
          return item.value;
        });
      }

      this.$validator.validateAll("memberkit").then(async(result) => {
        if (result) {
          await serviceIntegration
            .create(this.memberkit.data)
            .then(async(response) => {
              if (response.success) {
                this.$bvToast.toast("Regra de liberação criada com sucesso", {
                  title: "MemberKit",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                // fechando modal
                this.$bvModal.hide("modal-memberkit");
                //emitindo evento para o componente pai (opcoesEntrega)
                this.$emit("close");
                // limpando campos
                await this.changeConnection();
                EventBus.$emit("reloadMemberKitContent");
              } else {
                this.$bvToast.toast(
                  "Houve algum erro ao criar a Regra de liberação",
                  {
                    title: "MemberKit",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              console.error(error);
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },
    // limpa campos
    async changeConnection() {
      this.loading = true;

      this.memberkit.data.access = null;
      this.memberkit.options = [];
      this.memberkit.data.classroom_ids = [];
      this.memberkit.data.membership_id = null;

      this.memberkit.data.access = "classroom";
      await this.changeMemberKit(this.memberkit.data.access, true);

      setTimeout(async() => {
        this.memberkit.data.access = "subscription";
        await this.changeMemberKit();
      }, 1000);
    },
    // evento de change para ajustar o obj memberkit.data de acordo com cada tipo
    async changeMemberKit(e, no_search = false) {
      if (this.memberkit.data.access == "subscription") {
        delete this.memberkit.data.classroom_ids;
      } else if (this.memberkit.data.access == "classroom") {
        delete this.memberkit.data.membership_id;
      }

      if (!no_search) {
        await this.fetchIntegrationMemberKit();
      }
    },
    // puxa todas as integrações do memberkit e atribui
    async fetchIntegrationMemberKit() {
      this.loading = true;
      let data = {
        type: "memberkit",
        access: this.memberkit.data.access,
        connection_id: this.memberkit.data.connection_id,
      };

      await serviceIntegration
        .search(data)
        .then((response) => {
          this.memberkit.options = [];

          if (response.length) {
            if (this.memberkit.data.access == "subscription") {
              response.forEach((item) => {
                this.memberkit.options.push({
                  value: item.id,
                  text: item.name,
                });
              });
            } else {
              response.forEach((item) => {
                this.memberkit.options.push({
                  value: item.id,
                  text: `${item.course_name} | ${item.name}`,
                });
              });
            }
          } else {
            this.memberkit.options = [];
            this.$bvToast.toast(
              "Não encontramos dados cadastrados no Memberkit. Por gentileza verifique",
              {
                title: "MemberKit",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // funcao para puxar as conexoes e atribuir
    async fetchConnections() {
      this.memberkit.options_conexao = []
      await serviceConnection
        .search({ type: "memberkit" })
        .then((response) => {
          [...response].filter((item) => {
            if (item.type == "memberkit") {
              this.memberkit.options_conexao.push({
                value: item.id,
                text: item.title
              })
            }
          })
        })
        .catch((err) =>  console.error(err))
        .finally(() => { this.loading = false })
    },
    // abrindo modal e setando valores
    async openModal() {
      this.loading = true;
      // puxando as conexões do memberkit
      await this.fetchConnections();
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
</style>
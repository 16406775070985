<template>
  <b-overlay :show="loading">
    <main class="basic-informations">
      <section class="course-form">
        <!-- Images -->
        <section class="image">
          <b-form-group label="">
            <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              :data-images="product.images"
              idUpload="myIdUpload"
              editUpload="myIdEdit"
              :dragText="dragTextComputed"
              :browseText="$t('seller.products.new.text_584')"
              :showPrimary="false"
              :showEdit="false"
            ></vue-upload-multiple-image>

            <b-form-invalid-feedback
              :state="!(product.images.length == 0 && submit)"
            >
              {{ $t("seller.products.new.text_585") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </section>

        <section class="form">
          <!-- Name -->
          <b-form-group
            :label="$t('seller.products.new.text_586')"
            label-for="name"
          >
            <b-form-input
              id="name"
              name="name"
              :placeholder="$t('seller.products.new.text_587')"
              v-model="product.name"
              type="text"
              v-validate="'required'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              {{ $t("seller.products.new.text_588") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- Description -->
          <b-form-group
            class="description-container"
            :label="$t('seller.products.new.text_589')"
            label-for="description"
          >
            <div
              class="description-length"
              :class="{ ok: product.description.length >= 200 }"
              v-if="product.description.length"
            >
              {{ product.description.length }}
            </div>

            <b-form-textarea
              class="description-textarea"
              id="description"
              name="description"
              v-model="product.description"
              rows="3"
              v-validate="'required|min:200'"
              :placeholder="$t('seller.products.new.text_590')"
              :class="{ heaven: isHeaven }"
            ></b-form-textarea>
            <b-form-invalid-feedback :state="!errors.has('description')">
              {{ $t("seller.products.new.text_591") }}
            </b-form-invalid-feedback>
            <div class="mark-down d-flex justify-content-between mt-2">
              <p class="info-mark">{{ $t("seller.products.new.text_592") }}</p>
              <p
                href="#"
                class="open-mark"
                @click.prevent="openModal('editor-markdown')"
              >
                {{ $t("seller.products.new.text_593") }}
              </p>
            </div>
          </b-form-group>
          <!-- Sales Page -->
          <b-form-group
            :label="$t('seller.products.new.text_594')"
            label-for="sales_page"
          >
            <b-form-input
              id="sales_page"
              name="sales_page"
              :placeholder="$t('seller.products.new.text_595')"
              v-model="product.sales_page"
              type="text"
              v-validate="'required|url'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('sales_page')">
              {{ $t("seller.products.new.text_596") }}
            </b-form-invalid-feedback>
            <div class="mark-down mt-2">
              <p class="info-mark">
                {{ $t("seller.products.new.text_597") }}
              </p>
            </div>
          </b-form-group>
          <section class="inline-elements">
            <!-- Category -->
            <b-form-group
              class="max"
              :label="$t('seller.products.new.text_604')"
              label-for="category"
            >
              <b-form-select
                id="category"
                name="category"
                v-model="product.category_id"
                v-validate="'required'"
                :options="categories"
                :class="{ heaven: isHeaven }"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('category')">
                {{ $t("seller.products.new.text_605") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- Warranty -->
            <b-form-group
              class="max"
              :label="$t('seller.products.new.text_606')"
              label-for="warranty"
            >
              <b-form-input
                id="warranty"
                placeholder="30"
                name="warranty"
                v-model="product.warranty"
                v-mask="'###'"
                v-validate="'required|numeric|min_value:7'"
                type="text"
                :class="{ heaven: isHeaven }"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('warranty')">
                {{ $t("seller.products.new.text_607") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <section class="inline-elements">
            <!-- Charge Type -->
            <b-form-group
              class="max"
              :label="$t('seller.products.new.text_608')"
              label-for="type"
            >
              <b-form-select
                id="type"
                name="type"
                v-model="product.type"
                v-validate="'required'"
                :options="types"
                :class="{ heaven: isHeaven }"
                @change="validChange"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('type')">
                {{ $t("seller.products.new.text_609") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- Amount -->
            <b-form-group
              class="max"
              :label="$t('seller.products.new.text_610')"
              label-for="amount"
              v-if="showMoney"
            >
              <money
                v-if="product.type !== 'FREE'"
                class="max"
                id="amount"
                v-model="product.amount"
                v-bind="money"
                :class="{ heaven: isHeaven }"
              ></money>
              <b-form-input
                v-else
                id="amount"
                name="amount"
                v-model="amount_disabled"
                type="text"
                :class="{ heaven: isHeaven }"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </section>
          <!-- Methods -->
          <b-form-group
            data-anima="bottom"
            v-if="methods.length"
            :label="$t('seller.products.new.text_648')"
            label-for="method"
            class="method_select"
          >
            <multiselect
              v-model="method"
              id="multi_select_metodo"
              :options="methods"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="$t('seller.products.new.text_649')"
              label="name"
              track-by="name"
              selectLabel=""
              deselectLabel=""
              selectedLabel="✔️"
              class="multi-select-input"
              :class="{ heaven: isHeaven }"
              :disabled="product.type === 'FREE'"
            >
            </multiselect>
          </b-form-group>
          <section v-else class="skeleton-col">
            <b-skeleton
              animation="wave"
              width="20%"
              height="20px"
              data-anima="bottom"
            ></b-skeleton>
            <b-skeleton
              animation="wave"
              width="100%"
              height="70px"
              data-anima="bottom"
            ></b-skeleton>
          </section>
          <!-- Show only when type is subscription -->
          <!-- Period and trial -->
          <section
            class="inline-elements"
            v-if="product.type === 'SUBSCRIPTION'"
          >
            <!-- Period -->
            <b-form-group
              class="max"
              :label="$t('seller.products.new.text_611')"
              label-for="period"
              data-anima="top"
            >
              <b-form-select
                id="period"
                name="period"
                v-model="product.period"
                v-validate="'alpha_num'"
                :options="periods"
                :class="{ heaven: isHeaven }"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('period')">
                {{ $t("seller.products.new.text_612") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Trial -->
            <b-form-group
              class="max"
              :label="$t('seller.products.new.text_613')"
              label-for="trial"
              data-anima="top"
            >
              <b-form-input
                id="trial"
                name="trial"
                v-model="product.trial"
                type="number"
                :placeholder="$t('seller.products.new.text_614')"
                v-validate="'alpha_num'"
                :class="{ heaven: isHeaven }"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('trial')">
                {{ $t("seller.products.new.text_615") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- Custom period -->
          <b-form-group
            :label="$t('seller.products.new.text_616')"
            label-for="custom_period"
            data-anima="top"
            v-if="
              product.period === 'CUSTOM' && product.type === 'SUBSCRIPTION'
            "
          >
            <b-form-input
              name="custom_period"
              v-model="product.custom_period"
              type="number"
              :placeholder="$t('seller.products.new.text_650')"
              v-validate="'required|alpha_num'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('custom_period')">
              {{ $t("seller.products.new.text_617") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- Max charges -->
          <b-form-group
            :label="$t('seller.products.new.text_618')"
            label-for="charges"
            data-anima="top"
            v-if="product.type === 'SUBSCRIPTION'"
          >
            <b-form-input
              name="charges"
              v-model="product.charges"
              type="number"
              placeholder="Ex: 15 "
              v-validate="'alpha_num'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>
            <div class="mark-down mt-2" v-if="product.charges" data-anima="top">
              <p class="info-mark">
                {{ $t("seller.products.new.text_619") }} {{ product.charges }}x
                {{ $t("seller.products.new.text_620") }}
              </p>
            </div>
          </b-form-group>
        </section>
      </section>

      <EditorMarkDown :text_parent="product.description" from="new" />
    </main>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
// Components
import CustomColumns from "@/components/shared/CustomColumns.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import EditorMarkDown from "@/components/Seller/Products/modals/EditorMarkDown";
import CurrentType from "./CurrentType.vue";
import ProductChecklist from "./ProductChecklist.vue";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";

// Utils
import { EventBus } from "@/main.js";
import { symbolCurrency } from "@/helpers.js";
import { textMethodType } from "@/helpers.js";
import _refiner from "refiner-js";
import gtagSend from '@/utils/gtagSend';

// Services
import ProductService from "@/services/resources/ProductService";
import CategoryService from "@/services/resources/CategoryService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import CurrencyService from "@/services/resources/CurrencyService";
import ProductTypeService from "@/services/resources/ProductTypes";

// Build service
const serviceCurrency = CurrencyService.build();
const serviceUserSettings = UserSettingsService.build();
const serviceProduct = ProductService.build();
const serviceCategory = CategoryService.build();
const serviceProductType = ProductTypeService.build();

export default {
  name: 'BasicInformationCreateProduct',
  components: {
    Money,
    CustomColumns,
    VueUploadMultipleImage,
    EditorMarkDown,
    Multiselect,
    CurrentType,
    ProductChecklist,
  },
  props: {
    "submit-called": {
      type: Boolean,
      default: () => false,
    },
    defaultProductData: {
      type: [Object],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      product: {
        name: "",
        amount: 0,
        description: "",
        sales_page: "",
        category_id: null,
        warranty: null,
        type: null,
        trial: 0,
        is_checkout_address: false,
        method: [],
        images: [],
        links: [],
        period: 30,
        custom_period: null,
        charges: null,
      },
      showMoney: false,
      submit: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      periods: [
        { value: 30, text: this.$t("seller.products.new.text_629") },
        { value: 90, text: this.$t("seller.products.new.text_630") },
        { value: 180, text: this.$t("seller.products.new.text_631") },
        { value: 365, text: this.$t("seller.products.new.text_632") },
      ],
      loading: false,
      // Arrays
      categories: [],
      method: {},
      methods: [],
      allow_free_offers: null,
      amount_disabled: 'R$0,00',
      subUserAllowFreeOffers: false
    };
  },
  computed: {
    ...mapGetters(["getFlags"]),
    eventTickets() {
      if (this.getFlags.includes("feature_tickets")) return true;
      return false;
    },
    dragTextComputed() {
      if (this.currentType === 'event' && this.eventTickets) {
        return "550px por 355px"
      }
      return "120px por 120px"
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    types() {
      let types = [
        { value: null, text: this.$t("seller.products.new.text_626") },
        { value: "TRANSACTION", text: this.$t("seller.products.new.text_627") },
        { value: "SUBSCRIPTION", text: this.$t("seller.products.new.text_628") }
      ]
      if(this.allow_free && this.currentType !== 'physical_product') {
        types.push({ value: "FREE", text: this.$t("seller.products.new.text_662") })
      }
      if(this.currentType === 'event' && this.eventTickets) {
        types.splice(2, 1)
      }
      return types
    },
    currentType() {
      return this.$route.params.type;
    },
    currentTypeId() {
      return this.$store.state.createProduct.currentTypeId;
    },
    currentChecklistSteps() {
      return this.$store.state.createProduct.currentChecklistSteps || null;
    },
    currentStep() {
      return this.$store.state.createProduct.currentStep || null;
    },
    allow_free() {
      return this.$store.getters.setPanel ? this.subUserAllowFreeOffers : this.allow_free_offers
    }
  },
  watch: {
    submitCalled(val) {
      if(val) this.onSubmit()
    },
    'product.type'() {
      if(this.product.type === 'FREE' && this.product.amount !== 0) {
        this.product.amount = 0
        this.method = {}
        this.product.trial = 0
        this.product.period = 30
        this.product.charges = null
      }
    }
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
      this.product.images = fileList;
    },
    async toSendEventGA4(product){
      let nameEvent = "";
      const productTypes = [
        { id: 1, type: 'Mentoria' },
        { id: 2, type: 'Curso' },
        { id: 3, type: 'Evento' },
        { id: 4, type: 'Produto Fisico' },
        { id: 5, type: 'Outros' },
        { id: 6, type: 'Arquivo' }
      ];

      const productType =  productTypes.find(types => types.id === product.product_type_id );
      const categoryType =  this.categories.find(types => types.value === product.category_id );
      
      let objectEvent = {
        id_cliente: this.$store.getters.getProfile.id,
        id_produto: product.id, 
        tipo_do_produto: productType.type,
        nome_do_produto: product.name,
        descricao_do_produto: product.description,
        categoria: categoryType.text,
        tipo_de_cobranca: product.type,
        preco: product.amount,
        metodos_de_pagamento: product.method,
      }

      if(productType.type == "Mentoria"){
        nameEvent = "criacao_produto_mentoria";
        objectEvent = {
          ...objectEvent,
          pagina_de_vendas: this.product.sales_page,
        };
      }else if(productType.type == "Evento"){
        nameEvent = "criacao_produto_evento"
        objectEvent = {
          ...objectEvent,
          pagina_de_vendas: this.product.sales_page ,
          quantidade_de_lotes_criados: 1,
          titulo_do_lote: 'Primeiro Lote',
          valor_do_lote: product.amount,
        };
      }else{
        nameEvent = "criacao_produto"
        objectEvent = {
          ...objectEvent,
          id_oferta: product.offer_id,
        };
      }
      gtagSend(nameEvent, objectEvent)
    },
    beforeRemove(index, done, fileList) {
      var r = confirm(this.$t("seller.products.new.text_645"));
      if (r == true) {
        this.product.images.splice(index, 1);
        done();
      }
    },
    editImage(formData, index, fileList) {
      this.product.images = fileList;
    },
    changeFormatProduct() {
      this.types = [
        { value: null, text: this.$t("seller.products.new.text_626") },
        { value: "TRANSACTION", text: this.$t("seller.products.new.text_627") },
        {
          value: "SUBSCRIPTION",
          text: this.$t("seller.products.new.text_628"),
        },
      ];
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    async fetchCategories() {
      this.categories = [
        {
          value: null,
          text: this.$t("seller.products.new.text_644"),
          disabled: true,
        },
      ];

      await serviceCategory
        .search()
        .then((response) => {
          response.forEach(({ id, name }) => {
            if(name !== 'Infoproduto' && name !== 'Cursos') {
              this.categories.push({ value: id, text: name });
            }
          });
        })
        .finally(() => {
          this.submit = false;
        });
    },
    async fetchProductType() {
      await serviceProductType
        .search({url: 'show', type: this.$route.params.type })
        .then((response) => {
          this.product_type_id = response.id;
        })
        .finally(() => {
        });
    },
    validChange(e) {
      this.currencie_id = this.isHeaven ? 2 : 1;
      this.changeCurrency(this.currencie_id);
    },
    filterCurrency(id) {
      return this.isHeaven ? "$" : "R$";
    },
    async fetchCurrency() {
      this.currencies = [];

      await serviceCurrency
        .read({ id: "/currencies" })
        .then((response) => {
          response.forEach(({ id, name, currency }) => {
            if (
              (this.product.type === "SUBSCRIPTION" && currency === "BRL") ||
              this.product.type !== "SUBSCRIPTION"
            ) {
              this.currencies.push({ id, name });
            }
          });
        })
        .catch((err) => console.error(err));
    },
    async changeCurrency() {
      const value = this.isHeaven ? 2 : 1;
      const data = { id: "/payment_methods/" + value };
      this.method = [];
      this.methods = [];
      this.money.prefix = symbolCurrency(value);
      await serviceCurrency
        .read(data)
        .then((response) => {
          response.forEach(({ payment_methods }) => {
            if (this.product.type === "SUBSCRIPTION") {
              if (value !== 1) {
                if (payment_methods.name === "credit_card") {
                  this.methods.push({
                    value: textMethodType(payment_methods.name).toUpperCase(),
                    name: this.textMethod(payment_methods.name).toUpperCase(),
                  });
                }
              } else {
                if (
                  payment_methods.name === "credit_card" ||
                  payment_methods.name === "boleto" ||
                  payment_methods.name === "pix"
                ) {
                  this.methods.push({
                    value: textMethodType(payment_methods.name).toUpperCase(),
                    name: this.textMethod(payment_methods.name).toUpperCase(),
                  });
                }
              }
            } else {
              if(this.eventTickets && this.currentType === 'event'){
                if (payment_methods.name === "credit_card" ||
                  payment_methods.name === "boleto" ||
                  payment_methods.name === "pix") {
                  this.methods.push({
                    value: textMethodType(payment_methods.name).toUpperCase(),
                    name: this.textMethod(payment_methods.name).toUpperCase(),
                  });
                }
              }
              else if (this.currentType === "physical_product" ) {
                if (payment_methods.name !== "paypal") {
                  this.methods.push({
                  value: textMethodType(payment_methods.name).toUpperCase(),
                  name: this.textMethod(payment_methods.name).toUpperCase(),
                });
                }
              }
              else {
                this.methods.push({
                  value: textMethodType(payment_methods.name).toUpperCase(),
                  name: this.textMethod(payment_methods.name).toUpperCase(),
                });
              }
            }
          });
        })
        .catch((err) => console.error(err));
    },
    handlePaymentMethods(value, payment_methods) {
      if (this.product.type === "SUBSCRIPTION") {
        if (value !== 1) {
          if (payment_methods.name === "credit_card") {
            this.methods.push({
              value: textMethodType(payment_methods.name).toUpperCase(),
              name: this.textMethod(payment_methods.name).toUpperCase(),
            });
          }
        } else {
          if (["credit_card", "boleto", "pix"].includes(payment_methods.name)) {
            this.methods.push({
              value: textMethodType(payment_methods.name).toUpperCase(),
              name: this.textMethod(payment_methods.name).toUpperCase(),
            });
          }
        }
      } else {
        if (this.eventTickets && this.currentType === 'event') {
          if (payment_methods.name === "credit_card" ||
            payment_methods.name === "boleto" ||
            payment_methods.name === "pix") {
            this.methods.push({
              value: textMethodType(payment_methods.name).toUpperCase(),
              name: this.textMethod(payment_methods.name).toUpperCase(),
            });
          }
        } else if (this.currentType === "physical_product") {
          if (payment_methods.name !== "paypal") {
            this.methods.push({
              value: textMethodType(payment_methods.name).toUpperCase(),
              name: this.textMethod(payment_methods.name).toUpperCase(),
            });
          }
        } else {
          this.methods.push({
            value: textMethodType(payment_methods.name).toUpperCase(),
            name: this.textMethod(payment_methods.name).toUpperCase(),
          });
        }
      }
      console.log('[handlePaymentMethods] this.methods: ', this.methods);
    },
    setMoney() {
      if (this.isHeaven) {
        this.money = {
          decimal: ",",
          thousands: ".",
          prefix: "$ ",
          suffix: "",
          precision: 2,
          masked: false,
        };
      }
      this.showMoney = true;
    },
    textMethod(method) {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "CREDIT-CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "BANKTRANSFER":
            return this.$t("views.seller.edit_product.text_1113");
          case "CREDITCARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "DEBITCARD":
            return this.$t("views.seller.edit_product.text_1114");
          case "debitcard":
            return this.$t("views.seller.edit_product.text_1114");
          case "TWO_CREDIT_CARDS":
            return this.$t("views.seller.edit_product.text_1115");
          case "DEBITCARD":
            return "CARTÃO DE DÉBITO";
          case "EFT":
            return "EFT";
          case "SPEI":
            return "SPEI";
          case "SENCILLITO":
            return "SENCILLITO";
          case "OXXO":
            return "OXXO";
          default:
            return method;
        }
      } else return method;
    },
    async onSubmit() {
      this.submit = true;
      this.$store.commit("createProduct/setLoading", true);
      this.$validator.validateAll().then(async (result) => {
        if (result && this.product.images.length > 0) {
          if (
            (
              !this.allow_free ||
              (this.allow_free && (this.product.format === 'PHYSICALPRODUCT' || this.currentType === 'physical_product' || this.product.type === "SUBSCRIPTION" || this.product.type === "TRANSACTION"))
            ) &&
            this.product.amount < 9
          ) {
            this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 9,00`, {
              title: this.$t("views.cobranca.text_2169"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            if (document.querySelector("#amount")) {
              document.querySelector("#amount").focus();
            }
            this.submit = false;
            this.loading = false
            this.$store.commit("createProduct/setLoading", false);
            this.$emit("errorStep")
            return false;
          }

          if (this.warranty < 7) {
            this.$bvToast.toast(this.$t("seller.products.new.text_635"), {
              title: this.$t("seller.products.new.text_658"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.submit = false;
            this.loading = false
            this.$store.commit("createProduct/setLoading", false);
            this.$emit("errorStep")
            return;
          }

          // validate product method
          if (
            (
              !this.allow_free ||
              (this.allow_free && (this.product.format === 'PHYSICALPRODUCT' || this.currentType === 'physical_product' || this.product.type === "SUBSCRIPTION" || this.product.type === "TRANSACTION"))
            ) &&
            (!this.method || JSON.stringify(this.method) === "[]")
          ) {
            this.$bvToast.toast(this.$t("seller.products.new.text_659"), {
              title: this.$t("seller.products.new.text_658"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.submit = false;
            this.loading = false
            this.$store.commit("createProduct/setLoading", false);
            this.$emit("errorStep")
            return;
          }

          if(this.allow_free && this.product.amount === 0) {
            if(this.product.format === 'PHYSICALPRODUCT' || this.currentType === 'physical_product') {
              this.$bvToast.toast(this.$t('seller.products.new.text_660'), {
                title: this.$t('seller.products.new.text_658'),
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: true
              })
              this.submit = false
              this.loading = false
              this.$store.commit("createProduct/setLoading", false);
              this.$emit("errorStep")
              return
            }
            if(this.product.type !== 'FREE') {
              this.$bvToast.toast(this.$t('seller.products.new.text_663'), {
                title: this.$t('seller.products.new.text_658'),
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: true
              })
              this.submit = false
              this.loading = false
              this.$store.commit("createProduct/setLoading", false);
              this.$emit("errorStep")
              return
            }
            this.product.method = 'FREE'
          } else {
            this.product.method = this.method.map((item) => item.value).join(",");
          }

          this.product = {
            ...this.product,
            currency_id: this.isHeaven ? 2 : 1,
            is_checkout_address: this.currentTypeId === 4,
            product_type_id: this.currentTypeId,
            charges:
              this.product.type === "SUBSCRIPTION" && this.product.charges
                ? +this.product.charges
                : null,
          };

          if (!!this.$route.params.product_id) {
            this.$store.dispatch("createProduct/updateProduct", {
              ...this.product,
              id: this.$route.params.product_id,
            }).then(() => {
              this.$emit("successStep")
            });
          } else {
            const product = Object.assign({}, this.product)
            if(this.product.type === 'FREE') product.type = "TRANSACTION"
            // Create product
            await serviceProduct
              .create(product)
              .then(async (response) => {
                await this.toSendEventGA4(response)
                await this.$store
                  .dispatch("createProduct/fetchProduct", response.id)
                  .then(() => {
                    this.$emit("successStep")
                  });
              })
            .catch(err => console.error(err))
            .finally(() => {
              this.$store.commit("createProduct/setLoading", false);
              this.submit = false;
            });
          }
        } else {
          this.$store.commit("createProduct/setLoading", false);
          this.$emit("errorStep")
        }
      });
    },
    clearFields() {
      this.product = {
        name: "",
        amount: 0,
        description: "",
        sales_page: "",
        category_id: null,
        warranty: 0,
        type: null,
        trial: 0,
        is_checkout_address: false,
        method: [],
        images: [],
        links: [],
        period: 30,
        custom_period: null,
        charges: null,
      };
      this.amount = 0;
      this.slug = null;
      this.currencie_id = 1;
      this.methods = [];
      this.changeCurrency();
    },
    async fetchUserSettings() {
      await serviceUserSettings
        .read({ id: JSON.parse(localStorage.user).id })
        .then((response) => {
          response.forEach((item) => {
            if (item.key === "PRODUCT_CUSTOM_PERIOD" && item.value === "1") {
              this.periods.push({
                value: "CUSTOM",
                text: this.$t("seller.products.new.text_646"),
              });
            }
            if(item.key === 'ALLOW_FREE_OFFERS' && item.value === 'ENABLED') {
              this.allow_free_offers = true
            }
          });
        });
    },
    async fetchSubUserSettings() {
      await serviceUserSettings
        .read({ id: this.$store.getters.setPanel })
        .then((response) => {
          response.forEach((item) => {
            if(item.key === 'ALLOW_FREE_OFFERS' && item.value === 'ENABLED') {
              this.subUserAllowFreeOffers = true
            }
          });
        });
    },
    loadDefaultValues() {
      this.product = this.defaultProductData ? {
        ...this.product,
        ...this.defaultProductData
      } : this.product;
      this.loadDefaultMethods();
    },
    loadDefaultMethods() {
      this.method = this.defaultProductData.defaultMethods.map((item) => {
        return {
          value: textMethodType(item).toUpperCase(),
          name: this.textMethod(item).toUpperCase()
        }
      });
    },
    async fetchProduct() {
      this.loading = true;
      await this.$store
        .dispatch("createProduct/fetchProduct", this.$route.params.product_id)
        .then(async (response) => {
          this.product = {
            ...response,
            sales_page: response.links
              .filter((item) => item.name === "MAIN")
              .pop().url,
          };
          await this.$store
            .dispatch("createProduct/fetchOffers")
            .then((offers) => {
              const defaultOffer = offers.filter((item) => item.default).pop();
              this.product.method = defaultOffer.method
                .split(",")
                .map((item) => ({
                  value: textMethodType(item).toUpperCase(),
                  name: this.textMethod(item).toUpperCase(),
                }));
              this.method = defaultOffer.method.split(",").map((item) => ({
                value: textMethodType(item).toUpperCase(),
                name: this.textMethod(item).toUpperCase(),
              }));
              if (this.method.length === 0) {
                this.loadDefaultMethods();
              }
              this.product.amount = defaultOffer?.amount || 0;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    EventBus.$on("textNewMarkDown", (data) => {
      this.product.description = data;
    });
    EventBus.$on("submit-step-2", () => {
      this.onSubmit();
    });
    this.setMoney();
    this.fetchUserSettings();
    if (this.$store.getters.setPanel) {
      this.fetchSubUserSettings()
    }
    this.fetchCategories();
    this.changeCurrency();
    if (!!this.$route.params.product_id) {
      this.fetchProduct();
    }
    this.loadDefaultValues();

  },
};
</script>

<style lang="scss" scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.basic-informations {
  display: block;
  width: 100%;
}

.skeleton-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.description-container {
  position: relative;
}
.description-length {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0c37;
  background: rgba(255, 12, 55, 0.1);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -10px;
  right: 0;

  &.ok {
    color: $secondary-color;
    background: rgba(33, 51, 210, 0.2);
  }
}

.description-textarea {
  height: 150px !important;
}

.method_select {
  z-index: 9999;
}
</style>

<style>
.multiselect--disabled {
  border: solid 0;
  border-radius: 5px;
}
.multiselect--disabled .multiselect__tags {
  background-color: #ededed;
}
</style>

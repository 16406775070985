import { render, staticRenderFns } from "./CurrentType.vue?vue&type=template&id=04d33096&scoped=true"
import script from "./CurrentType.vue?vue&type=script&lang=js"
export * from "./CurrentType.vue?vue&type=script&lang=js"
import style0 from "./CurrentType.vue?vue&type=style&index=0&id=04d33096&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d33096",
  null
  
)

export default component.exports
import axios from 'axios'
import Cookies from 'js-cookie';
import store from '@/store/index'


let headers = {
  'Access-Control-Allow-Origin': '*',
  'Authorization': Cookies.get('access_token'),
}

if (store.getters.setPanel !== null) {
  headers['X-Parent-User-ID'] = store.getters.setPanel;
} else {
    delete  headers['X-Parent-User-ID'];
}

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}/gdigital`,
  headers: headers
})
export const api_gdigital = {
  get(endpoint) {
    return axiosInstance.get(endpoint)
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body)
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body)
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint)
  }
}
<template>
  <BaseModal name="modal-conexoes" size="lg" :title="$t('seller.my_account.conexoes.text_1845')">
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <h4 class="title-inter">{{ description }}</h4>
      <p v-if="plataform === 'alpaclass'" class="description-details">
        <p class="description-details" v-html="$t('alpaclass_modal.connection_desc')"></p>
        <p class="description-details">{{ $t('alpaclass_modal.connection_desc_2') }}</p>
      </p>
      <br>
      <form>
        <b-form-group class="p-2" :label="$t('seller.my_account.conexoes.text_1850')">
          <b-form-input
            v-model="plataform"
            v-validate="'required'"
            name="plataform"
            disabled
            :class="{'heaven': isHeaven}"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('plataform') : null"
          >
            {{ $t('seller.my_account.conexoes.text_301') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group class="p-2" :label="$t('seller.my_account.conexoes.text_1849')" label-for="connection_name">
              <b-form-input
                id="connection_name"
                name="connection_name"
                v-model="connection_name"
                v-validate="'required'"
                type="text"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('connection_name') : null"
              >
                {{ $t('seller.my_account.conexoes.text_302') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group class="p-2" :label="plataform === 'alpaclass' ? $t('alpaclass_modal.access_token') : $t('seller.my_account.conexoes.text_1848')" label-for="api_key">
              <b-form-input
                id="api_key"
                name="api_key"
                v-model="connection_api_key"
                v-validate="'required'"
                type="text"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('api_key') : null"
              >
                {{ $t('seller.my_account.conexoes.text_1846') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.my_account.conexoes.text_1851') }}
      </BaseButton>

      <BaseButton
        variant="primary"
        :class="{'heaven': isHeaven}"
        :disabled="loading"
        @click="createIntegration"
        > {{ $t('seller.my_account.conexoes.text_1852') }} </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

export default {
  props: {
    connectionDescription: String,
    connectionType: String
  },
  data() {
    return {
      loading: false,
      submit: false,
      description: this.connectionDescription,
      plataform: this.connectionType,
      connection_name: null,
      connection_api_key: null,
      api_key: "",
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    createIntegration() {
      this.submit = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let data = {
            title: this.connection_name,
            type: this.plataform,
            api_key: this.connection_api_key,
          };

          serviceConnection
            .create(data)
            .then((response) => {
              this.$bvToast.toast(this.$t('seller.my_account.conexoes.text_303'), {
                title: this.$t('seller.my_account.conexoes.text_1854'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.connection_name = null;
              this.connection_api_key = null;
              this.plataform = null;
              this.$bvModal.hide("modal-conexoes");
              this.$emit("close");
            })
            .catch((err) => {
              console.log(err);
              this.$bvToast.toast(this.$t('seller.my_account.conexoes.text_304'), {
                title: this.$t('seller.my_account.conexoes.text_1854'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.description-details {
  font-size: 0.8em
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
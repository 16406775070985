<template>
  <custom-columns data-anima="top" class="container">
    <section class="init-content" :current-step="currentStep">
      <!-- First Step -->
      <basic-informations
        id="basic-informations"
        key="basic-informations"
        :default-product-data="defaultProductData"
        :submit-called="submitCalled"
        v-if="currentStep === 2"
        @successStep="successStep(true)"
        @errorStep="changeSubmitCalled(false)"
      />
      <!-- Second Step -->
      <component
        :id="currentType.type"
        :key="currentType.type"
        :submit-called="submitCalled"
        v-if="currentStep === 3"
        :product="product"
        :is="currentType.type"
        @successStep="successStep"
      ></component>
      <!-- Finish -->
      <finish-product-modal :product="product" v-if="product" />
      <section class="inline-elements" :second-step="currentStep === 2">
        <b-button class="buttons" size="lg" variant="light" @click="goBack">
          {{ $t("client.text_18") }}
        </b-button>
        <!-- Create Product Button -->
        <b-button
          class="buttons greenn-btn"
          :class="{ heaven: isHeaven }"
          :disabled="isLoading"
          @click="success"
        >
          <b-spinner v-if="isLoading" small></b-spinner>
          <template v-else>
            {{
              lastStep || currentStep === 2
                ? $t("seller.products.new.text_622")
                : $t("client.text_19")
            }}
          </template>
        </b-button>
      </section>
    </section>
    <template #after>
      <section class="after">
        <current-type />
        <product-checklist
          v-if="currentChecklist"
          :checklist="currentChecklist.checklist"
        ></product-checklist>
      </section>
    </template>
  </custom-columns>
</template>
<script>
// Components
import CustomColumns from "@/components/shared/CustomColumns.vue";
import ProductChecklist from "./ProductChecklist.vue";
import BasicInformations from "./BasicInformation.vue";
import CurrentType from "./CurrentType.vue";
import FinishProductModal from "./FinishProductModal.vue";
// Type Components
import Event from "./Event.vue";
import Mentoring from "./Mentoring.vue";
import Course from "./Course.vue";

export default {
  name: 'CreateProduct',
  components: {
    CustomColumns,
    BasicInformations,
    ProductChecklist,
    CurrentType,
    FinishProductModal,
    Event,
    Mentoring,
    Course,
  },
  data() {
    return {
      submitCalled: false,
      defaultProductData: {
        warranty: 7,
        type: 'TRANSACTION',
        defaultMethods: [
          'credit-card',
          'boleto',
          'pix',
          'paypal',
          'two_credit_cards',
        ]
      }
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    productId() {
      return this.$route.params.product_id || null;
    },
    currentType() {
      return this.$store.state.createProduct.currentType;
    },
    currentStep() {
      return this.$store.state.createProduct.currentStep;
    },
    currentChecklist() {
      return this.$store.state.createProduct.currentChecklist;
    },
    isLoading() {
      return this.$store.state.createProduct.loading;
    },
    product() {
      return this.$store.state.createProduct.product;
    },
    lastStep() {
      return this.currentChecklist.steps === this.currentStep;
    },
  },
  methods: {
    goBack() {
      if (this.currentStep === 2) {
        this.$router.push("/novo-produto");
      } else {
        this.$store.commit(
          "createProduct/setCurrentStep",
          this.currentStep - 1
        );
        this.$store.commit("createProduct/resolveCurrentChecklist");
      }
    },
    changeSubmitCalled(value = !this.submitCalled) {
      this.submitCalled = value;
    },
    success() {
      this.changeSubmitCalled(true);
    },
    async successStep(showFinishModal = false) {
      this.changeSubmitCalled(false);
      if (this.lastStep || showFinishModal) {
        this.showFinishModal();
      } else {
        this.$store.commit(
          "createProduct/setCurrentStep",
          this.currentStep + 1
        );
        this.$store.commit("createProduct/resolveCurrentChecklist");
        if (!this.productId) {
          this.$router.push({
            params: {
              type: this.currentType.type,
              product_id: this.product.id,
            },
          });
        }
        this.$store.commit("createProduct/setLoading", false);
      }
    },
    async showFinishModal() {
      await this.$store.dispatch("createProduct/fetchProduct", this.product.id).then(async res => {
        if (["DRAFT", "REQUESTED"].includes(res.status_product)) {
          this.$router.push(`/produtos/edicao-produto/${res.id}`)
        } else {
          await this.$store.dispatch("createProduct/fetchOffers");
          this.$bvModal.show("finish-product");
        }
      });
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchFlags')
    // Validate current step
    if (this.currentStep === 1) {
      this.$store.commit("createProduct/setCurrentStep", 2);
      this.$store.commit("createProduct/resolveCurrentChecklist");
    }
    // When exist product id, call fetch product
    if (!!this.productId) {
      this.$store.commit("createProduct/setLoading", true);
      await this.$store
        .dispatch("createProduct/fetchProduct", this.productId)
        .then(() => {
          this.successStep();
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.greenn-btn {
  background: $base-color;
  color: #fff;
  outline: none !important;
  border: none;
  transition: all 0.3s;
}

.greenn-btn:active {
  background: $base-color !important;
  background-color: $base-color-dark !important;
  border-style: none !important;
  border: none;
}

.greenn-btn:hover {
  transform: scale(1.07);
}

.heaven {
  background: $base-color-heaven !important;
  border-color: $base-color-heaven !important;
}
[second-step] {
  padding-left: 230px;
}

[current-step="2"] {
  margin-bottom: 150px;
}
</style>

<template>
  <main class="custom">
    <slot name="before"></slot>
    <section class="content">
      <slot></slot>
    </section>
    <slot name="after"></slot>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.custom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
</style>
import Rest from '@/services/Rest';

/**
 * @typedef {GoogleSheetsService}
 */
export default class GoogleSheetsService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/googlesheet'
}

import Rest from '@/services/Rest';

/**
 * @typedef {ConnectionService}
 */
export default class ConnectionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/connection'
}

<template>
  <BaseModal
    name="modal-alpaclass"
    size="lg"
    :title="$t('alpaclass_modal.title')"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• AlpaClass</h4>
          <div class="d-flex mb-4">
            <BaseButton
              variant="black"
              class="gerencia-conexoes"
              @click="openConexoes"
              > 
              {{ $t('alpaclass_modal.connection_button') }}
            </BaseButton>
          </div>
          <b-form
            v-if="alpaclass.options_conexao.length"
            data-vv-scope="alpaclass"
          >
            <b-row>
              <b-col class="mr-3">
                <b-form-group :label="$t('alpaclass_modal.connection')" label-for="conexao">
                  <b-form-select
                    v-model="alpaclass.data.connection_id"
                    :options="alpaclass.options_conexao"
                    name="conexao"
                    id="conexao"
                    v-validate="'required'"
                    @change="changeConnection"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('alpaclass.conexao')">
                    {{ $t('alpaclass_modal.error') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <p v-if="this.alpaclass.options.length">
              {{ $t('alpaclass_modal.tutorial_step1') }}
          </p>
            <b-form-group
              :label="$t('alpaclass_modal.label_1')"
              label-for="classroom"
              v-if="
                this.alpaclass.options.length
              "
              data-anima="top"
            >
              <multiselect
                v-model="alpaclass.data.classroom_ids"
                :options="alpaclass.options"
                :value="alpaclass.data.classroom_ids"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('alpaclass_modal.placeholder_1')"
                label="text"
                track-by="text"
                selectLabel=""
                deselectLabel=""
                selectedLabel="✔️"
                class="multi_select_alpaclass"
                id="multi_select_alpaclass"
                name="classroom"
                v-validate="'required'"
              >
              </multiselect>
              <b-form-invalid-feedback :state="!errors.has('alpaclass.classroom')">
                {{ $t('alpaclass_modal.error') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="p-2" :label="$t('alpaclass_modal.label_2')" label-for="delivery_id">
              <b-form-input
                id="delivery_id"
                name="delivery_id"
                v-model="alpaclass.data.delivery_id"
                v-validate="'required'"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('alpaclass.delivery_id')">
                {{ $t('alpaclass_modal.error') }}
              </b-form-invalid-feedback>
            </b-form-group>
            
          </b-form>
          <div v-else>
            <p>
              {{ $t('alpaclass_modal.desc_1') }}
            </p>
            <p>
              {{ $t('alpaclass_modal.desc_2') }}
            </p>
            <br />
            <p>
              {{ $t('alpaclass_modal.desc_3') }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes 
      :connection-description="$t('alpaclass_modal.description')" 
      connection-type="alpaclass" 
      @close="fetchConnections" 
    />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>
      <BaseButton
        v-if="alpaclass.options_conexao.length"
        variant="black"
        :disabled="loading || !alpaclass.options.length"
        @click="adicionarAlpaclass"
      >
        Adicionar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Multiselect from 'vue-multiselect'
import Conexoes from '../../MyAccount/modals/Conexoes'

import ConnectionService from '@/services/resources/ConnectionService'
import IntegrationService from '@/services/resources/IntegrationService'

const serviceConnection = ConnectionService.build()
const serviceIntegration = IntegrationService.build()

export default {
  data() {
    return {
      loading: false,
      submit: false,
      alpaclass: {
        data: {
          type: "alpaclass",
          product_id: null,
          connection_id: null,
        },
        options_conexao: [],
        options: [],
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Multiselect,
    Conexoes,
  },
  methods: {
    // abrir modal de conexoes
    openConexoes() {
      this.$bvModal.show("modal-conexoes");
    },

    async adicionarAlpaclass() {
      this.submit = true;
      this.loading = true;
      this.alpaclass.data.product_id = this.id_product;

      this.alpaclass.data.classroom_names = [this.alpaclass.data.classroom_ids.text];
      this.alpaclass.data.classroom_ids = [this.alpaclass.data.classroom_ids.value];
      this.alpaclass.data.delivery_id = this.alpaclass.data.delivery_id;

      this.$validator.validateAll("alpaclass").then(async(result) => {
        if (result) {
          await serviceIntegration
            .create(this.alpaclass.data)
            .then(async(response) => {
              if (response.success) {
                this.$bvToast.toast(this.$t('alpaclass_modal.success'), {
                  title: "alpaclass",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
    
                this.$bvModal.hide("modal-alpaclass");
            
                this.$emit("close");
                await this.changeConnection();
                EventBus.$emit("reloadAlpaClassContent");
              } else {
                this.$bvToast.toast(this.$t('alpaclass_modal.error_toast'),
                  {
                    title: "alpaclass",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              console.error(error);
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },

    // limpa campos
    async changeConnection() {
      this.loading = true;

      this.alpaclass.options = [];
      this.alpaclass.data.classroom_ids = [];
      this.alpaclass.data.member_ids = null;

      await this.changeAlpaclass(true);

      setTimeout(async() => {
        await this.changeAlpaclass();
      }, 1000);
    },

    async changeAlpaclass(no_search = false) {

      delete this.alpaclass.data.classroom_ids;
      
      if (!no_search) {
        await this.fetchIntegrationAlpaclass();
      }
    },

    async fetchIntegrationAlpaclass() {
      this.loading = true;
      let data = {
        type: "alpaclass",
        connection_id: this.alpaclass.data.connection_id,
      };

      await serviceIntegration
        .search(data)
        .then((response) => {

          this.alpaclass.options = [];

          if (response.data.contents.length) {
            response.data.contents.forEach((item) => {
              this.alpaclass.options.push({
                value: item.id,
                text: item.name,
              });
            });
            
          } else {
            this.alpaclass.options = [];
            this.$bvToast.toast($t('alpaclass_modal.not_found'),
              {
                title: "alpaclass",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    async fetchConnections() {
      await serviceConnection
        .search({
          type: "alpaclass"
        })
        .then((response) => {
          [...response].filter((item) => {
            if (item.type == "alpaclass") {
              this.alpaclass.options_conexao.push({
                value: item.id,
                text: item.title,
              });
            }
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async openModal() {
      this.loading = true;
      await this.fetchConnections();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
</style>
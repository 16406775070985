<template>
  <section class="create_product-content">
    <section>
      <h2 class="default-font">
        {{ $t("views.seller.create_product.views.mentoring.title") }}
      </h2>
      <p class="subtitle">
        {{ $t("views.seller.create_product.views.mentoring.subtitle") }}
      </p>
    </section>
    <section class="inline-fields">
      <b-form-group
        :label="$t('views.seller.create_product.views.mentoring.period')"
        label-for="mentoring_period"
      >
        <b-form-input
          id="mentoring_period"
          name="mentoring_period"
          v-model="mentoring_period"
          type="text"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
          :placeholder="
            $t('views.seller.create_product.views.mentoring.period_placeholder')
          "
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('mentoring_period')">
          {{ $t("views.seller.create_product.inputs.mentoring.period_error") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="$t('views.seller.create_product.views.mentoring.duration')"
        label-for="mentoring_duration"
      >
        <b-form-input
          id="mentoring_duration"
          name="mentoring_duration"
          v-model="mentoring_duration"
          type="text"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
          :placeholder="
            $t(
              'views.seller.create_product.views.mentoring.duration_placeholder'
            )
          "
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('mentoring_duration')">
          {{
            $t("views.seller.create_product.inputs.mentoring.duration_error")
          }}
        </b-form-invalid-feedback>
      </b-form-group>
    </section>
  </section>
</template>
<script>
export default {
  data() {
    return {
      mentoring_period: "",
      mentoring_duration: "",
    };
  },
  props: {
    product: {
      type: [Object, Boolean],
      required: false,
      default: () => false,
    },
    "submit-called": {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    submitCalled(val) {
      if (val) this.finish();
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  methods: {
    async finish() {
      if (this.$route.params.type === "mentoring") {
        await this.$store
          .dispatch("createProduct/updateProduct", {
            ...this.product,
            mentoring_period: this.mentoring_period,
            mentoring_duration: this.mentoring_duration,
          })
          .then(() => {
            this.$emit("successStep")
          });
      }
    },
  }
};
</script>

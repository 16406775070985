<template>
  <list-item
    v-if="!loading"
    title="Alpaclass"
    :list="list"
    @delete="removeAlpaclass"
  />
  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import Vue from "vue";

// helper:
import { EventBus } from "@/main.js";

// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";

// services:
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      showList: true,
      regras: [],
      lista: [],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  computed: {
    list() {
      return this.lista.map((item) => ({
        id: item.id,
        name: `#${item.id} - ${item.name}`,
        actions: ["delete"],
      }));
    },
  },
  methods: {
    async removeAlpaclass(payload) {
      const { id, name } = payload;
      let texto = "";
      if (name) {
        if (name.indexOf("|") > -1) {
          texto = this.$t("alpaclass_modal.remove_delivery_4", { id: id });
        } else {
          texto = this.$t("alpaclass_modal.remove_delivery_2");
        }
      } else texto = this.$t("alpaclass_modal.remove_delivery_3");

      Vue.swal({
        title: this.$t("alpaclass_modal.remove_delivery"),
        text: texto,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id: id,
          };

          await serviceIntegration
            .destroy(data)
            .then((response) => {
              if (response.success) {
                this.$bvToast.toast(
                  this.$t("alpaclass_modal.remove_delivery_5"),
                  {
                    title: "Alpaclass",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                // atualizando front
                // this.lista.splice(index_lista, 1);
                this.lista = [...this.lista].filter((item) => {
                  if (item.id != data.id) {
                    return item;
                  }
                });
                EventBus.$emit("integracoesLista", -1);

              } else {
                this.$bvToast.toast(
                  this.$t("alpaclass_modal.remove_delivery_6"),
                  {
                    title: "Alpaclass",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // puxando todas as regras de liberação
    async getRegrasLiberacao() {
      return new Promise(async(resolve) => {
        // forma nova, passando filtro
        let data = {
          product_id: this.id_product,
          type: "alpaclass",
          url: "list",
        };
        await serviceIntegration
          .search(data)
          .then((response) => {
            this.regras = response;
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            this.regras = [];
            resolve(false);
          });
      });
    },

    // inciando puxando as regras
    async initRegras() {
      this.loading = true;
      // função que puxa todas as regras
      await this.getRegrasLiberacao().then((r) => {
        if (r) {
          this.lista = [];
          if (this.regras.length) {
            [...this.regras].reverse().forEach((regra) => {
              // verificando tipo
              if (regra.type == "alpaclass") {
                this.lista.push({
                  name: regra.data.classroom_names[0],
                  id: regra.id,
                  connection_id: regra.data.connection_id,
                });
              }
            });
          }
        }
        this.loading = false;
      });
    },
  },
  async mounted() {
    // iniciando puxando as regras
    await this.initRegras();
    EventBus.$on("reloadAlpaClassContent", async () => {
      await this.initRegras(); 
    });
  },
};
</script>

const Money = {
    methods: {
        formatMoney(number, moneyPrefix = '') {
            const result = moneyPrefix ? moneyPrefix + ' ' : '';
            if (typeof number !== 'undefined' && number) {
                return result + number.toLocaleString('pt-BR', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                });
            }
            return result + '0,00';
        }
    }
}

export default Money

<template>
  <BaseModal
    name="modal-voxuy"
    size="lg"
    :title="$t('seller.products.voxuy.text_719')"
    @shown="openModal"
    @hidden="closeModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Voxuy</h4>
          <div class="d-flex mb-2" data-anima="top">
            <b-col cols="12" data-anima="top">
              <BaseButton
                v-if="
                  gerenciar === false &&
                  !newConnection &&
                  selectedConection === null
                "
                @click="newConnection = true"
                variant="black"
                class=""
              >
                {{ $t('seller.products.voxuy.text_720') }}
              </BaseButton>
              <b-col v-if="newConnection" rows="12" cols="12">
                <b-row>
                  <b-form
                    style="display: flex; width: 100%; flex-wrap: wrap"
                    novalidate
                  >
                    <b-col cols="12" md="12">
                      <b-form-group class="p-2" :label="$t('seller.products.voxuy.text_722')" label-for="title">
                        <b-form-input
                          v-validate="'required'"
                          name="title"
                          id="title"
                          :placeholder="$t('seller.products.voxuy.text_723')"
                          type="text"
                          v-model="payload.title"
                          autocomplete="off"
                          :class="{'heaven': isHeaven}"
                        />
                        <b-form-invalid-feedback :state="!errors.has('title')">
                          {{ $t('seller.products.voxuy.text_721') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group class="p-2" :label="$t('seller.products.voxuy.text_724')" label-for="token">
                        <b-form-input
                          name="token"
                          id="token"
                          placeholder=""
                          type="text"
                          v-model="payload.api_token"
                          autocomplete="off"
                          :class="{'heaven': isHeaven}"
                        />
                        <b-form-invalid-feedback :state="!errors.has('token')">
                          {{ $t('seller.products.voxuy.text_725') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" md="12">
                      <b-form-group
                        class="p-2"
                        :label="$t('seller.products.voxuy.text_726')"
                        label-for="url_webhook"
                      >
                        <b-form-input
                          v-validate="{ url: { require_protocol: true } }"
                          name="url_webhook"
                          id="url_webhook"
                          placeholder="https://plataforma.com/api"
                          type="text"
                          v-model="payload.url_webhook"
                          autocomplete="off"
                          :class="{'heaven': isHeaven}"
                        />
                        <b-form-invalid-feedback
                          :state="!errors.has('url_webhook')"
                        >
                          {{ $t('seller.products.voxuy.text_727') }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-form>
                </b-row>
              </b-col>
              <div class="d-flex">
                <BaseButton
                  v-if="gerenciar === false && newConnection"
                  variant="link-info"
                  class="gerencia-conexoes mt-3 mr-3"
                  @click="newConnection = false"
                >
                  {{ $t('seller.products.voxuy.text_740') }}
                </BaseButton>
                <BaseButton
                  v-if="gerenciar === false && newConnection"
                  variant="black"
                  class="gerencia-conexoes mt-3"
                  @click="createVoxuy"
                >
                  {{ $t('seller.products.voxuy.text_741') }}
                </BaseButton>
              </div>
              <iframe
                v-if="openIntFrame"
                height="0"
                width="0"
                style="display: none"
                :src="srcIframe"
              ></iframe>
            </b-col>
          </div>
          <div
            v-if="infusionsoft.length === 0 && gerenciar === false"
            data-anima="top"
          >
            <p>
              {{ $t('seller.products.voxuy.text_728') }}
            </p>
            <p>
              {{ $t('seller.products.voxuy.text_729') }}
            </p>
            <br />
            <p>{{ $t('seller.products.voxuy.text_730') }}</p>
          </div>
          <b-col
            v-if="infusionsoft.length !== 0 && !newConnection"
            cols="12"
            data-anima="top"
          >
            <ul class="mt-3" v-if="selectedConection === null">
              <li
                v-for="item in infusionsoft"
                :key="item.title + item.id"
                data-anima="bottom"
              >
                <div @click="selectItem(item.id)">
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ item.id }} - {{ item.title }}
                      <p data-anima="bottom" class="ativo">
                        {{ $t('seller.products.voxuy.text_742') }}
                        {{
                          moment(item.created_at)
                            .locale("pt-br")
                            .format("DD/MM/YYYY [às] HH:mm")
                        }}
                      </p>
                    </span>
                  </div>
                </div>
                <div class="acoes-webhook">
                  <img
                    src="@/assets/img/icons/lixeira.svg"
                    class="icon-lixeira"
                    :id="'btn-remover-' + item.id"
                    @click="removeConection(item.id)"
                  />
                  <b-tooltip
                    :target="'btn-remover-' + item.id"
                    :title="$t('seller.products.voxuy.text_743')"
                    placement="top"
                  />
                </div>
              </li>
            </ul>

            <ul v-else>
              <li data-anima="bottom">
                <div>
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ selectedConection }} -
                      {{ filterItem(selectedConection)[0].title }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col
            v-if="selectedConection"
            cols="12"
            class="mt-4"
            data-anima="top"
          >
            <h6 class="titulo-acao" v-if="selectedTypeAction !== null">
              {{ $t('seller.products.voxuy.text_731') }}
            </h6>
            <b-form novalidate>
              <h4 class="title-inter">{{ $t('seller.products.voxuy.text_731') }}</h4>
              <div class="grid-events">
                <b-form-group :label="$t('seller.products.voxuy.text_744')" label-for="check">
                  <b-form-checkbox
                    v-for="(op, index) in events.options_sales"
                    :key="op.value"
                    :id="`check-${index}`"
                    :name="`check-${index}`"
                    v-model="selectEvent[index]"
                    size="md"
                    switch
                    class="check-box mb-2"
                    @change="triggerChanged(op.value)"
                  >
                    <p class="info-checkbox">{{ op.text }}</p>
                    <div
                      v-show="op.value == 'saleRefused' && selectEvent[1]"
                      data-anima="top"
                      class="mt-3 mb-3"
                    >
                      <b-form-checkbox
                        size="md"
                        switch
                        v-for="(sub, index) in events.options_salesRefused"
                        class="check-box mb-2"
                        :name="`check-refunsed-${index}`"
                        :id="`check-refunsed-${index}`"
                        :key="sub.value"
                        v-model="selectEventRefunsed[index]"
                        @change="subTriggerChanged(sub.value, 'saleRefused')"
                      >
                        <p class="info-checkbox">{{ sub.text }}</p>
                      </b-form-checkbox>
                    </div>
                    <div
                      v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                      data-anima="top"
                      class="mt-3 mb-3"
                    >
                      <b-form-checkbox
                        v-for="(sub, index) in events.options_salesWaiting"
                        :key="sub.value"
                        :id="`check-waiting-${index}`"
                        :name="`check-waiting-${index}`"
                        v-model="selectEventWaiting[index]"
                        size="md"
                        switch
                        class="check-box mb-2"
                        @change="subTriggerChanged(sub.value, 'saleWaitingPayment')"
                      >
                        <p class="info-checkbox">{{ sub.text }}</p>
                      </b-form-checkbox>
                    </div>
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group :label="$t('seller.products.voxuy.text_745')" label-for="check">
                  <b-form-checkbox
                    v-for="op in events.options_sub"
                    :key="op.value"
                    size="md"
                    switch
                    class="check-box mb-2"
                    name="check"
                    @change="triggerChanged(op.value)"
                  >
                    <p class="info-checkbox">{{ op.text }}</p>
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group :label="$t('seller.products.voxuy.text_746')" label-for="check">
                  <b-form-checkbox
                    v-for="op in events.options_checkout"
                    :key="op.value"
                    size="md"
                    switch
                    class="check-box mb-2"
                    name="check"
                    @change="triggerChanged(op.value)"
                  >
                    <p class="info-checkbox">{{ op.text }}</p>
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-form>
          </b-col>
          <span>
            <b-col
              v-if="editMeta || !metas.length"
              cols="12"
            >
              <b-form-group class="p-2" :label="$t('seller.products.voxuy.text_747')" label-for="plan_id">
                <b-form-input
                  id="plan_id"
                  name="plan_id"
                  placeholder=""
                  type="text"
                  v-model="payload.plan_id"
                  autocomplete="off"
                  :class="{'heaven': isHeaven}"
                />
                <b-form-invalid-feedback :state="!errors.has('plan_id')">
                  {{ $t('seller.products.voxuy.text_748') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col v-if="editMeta || !metas.length">
              <b-col cols="12">
                <b-form-group
                  class="p-2"
                  :label="$t('seller.products.voxuy.text_749')"
                  label-for="nome_produto"
                >
                  <b-form-input
                    name="nome_produto"
                    id="nome_produto"
                    placeholder="ex: Greenn"
                    type="text"
                    v-model="payload.name"
                    autocomplete="off"
                    :class="{'heaven': isHeaven}"
                  />
                  <b-form-invalid-feedback :state="!errors.has('nome_produto')">
                    {{ $t('seller.products.voxuy.text_750') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col v-if="metas.length && !editMeta">
              <div>
                <div
                  v-for="meta in metas"
                  :key="meta.id"
                  class="pixel"
                  data-anima="top"
                >
                  <div>
                    <div class="pixel-type">
                      <p class="mt-2">
                        <b>product_name: </b>{{ JSON.parse(meta.value).name }}
                      </p>
                      <p class="mt-2">
                        <b>plan_id: </b>{{ JSON.parse(meta.value).plan_id }}
                      </p>
                    </div>
                    <p class="mt-2">{{ meta.updated_at | datetime }}</p>
                    <BaseButton
                      @click="changeEditMeta(meta)"
                      variant="black"
                      class=""
                    >
                      {{ $t('seller.products.voxuy.text_751') }}
                    </BaseButton>
                  </div>
                </div>
              </div>
            </b-col>
          </span>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('client.text_51') }}
      </BaseButton>
      <BaseButton
        v-if="
          gerenciar === false &&
          infusionsoft.length !== 0 &&
          selectedConection !== null
        "
        variant="black"
        :disabled="loading"
        @click="createCallback"
      >
        {{ $t('seller.products.voxuy.text_752') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Conexoes from '../../MyAccount/modals/Conexoes'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import gtagSend from '@/utils/gtagSend';

import ConnectionService from '@/services/resources/ConnectionService'
import InfusionsoftService from '@/services/resources/InfusionsoftService'
import ProductService from '@/services/resources/ProductService'
import CallbackService from '@/services/resources/CallbackService'

const serviceConnection = ConnectionService.build()
const infusionsoftService = InfusionsoftService.build()
const productionService = ProductService.build()
const serviceCallback = CallbackService.build()


export default {
  data() {
    return {
      metaIsEdited: false,
      editMeta: false,
      editMetaItem: null,
      moment: moment,
      payload: {
        name: null,
        plan_id: null,
      },
      selectedTypeAction: null,
      makePlanilha: false,
      newPlanilha: "",
      nameConnection: "",
      newConnection: false,
      optionsConection: [{ id: null, name: this.$t('seller.products.voxuy.text_734') }],
      selectedConection: null,
      openIntFrame: false,
      srcIframe: "",
      loading: false,
      submit: false,
      infusionsoft: [],
      connection_name: null,
      connection_api_key: null,
      connection_base_url: null,
      lista_options: [],
      lista_options_prev: null,
      lista_options_next: null,
      select_callback: "voxuy",
      trigger: [],
      metas: [],
      callback: [],
      lista: null,
      gerenciar: false,
      multiselect: true,
      selectEvent: [],
      selectEventRefunsed: [],
      selectEventWaiting: [],
      events: {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        url: "",
        callback: "voxuy",
        options_sales: [
          {
            value: "salePaid",
            text: this.$t('seller.products.options_sales.text_751'),
          },
          {
            value: "saleRefused",
            text: this.$t('seller.products.options_sales.text_752'),
          },
          {
            value: "saleRefunded",
            text: this.$t('seller.products.options_sales.text_753'),
          },
          {
            value: "saleChargedback",
            text: this.$t('seller.products.options_sales.text_754'),
          },
          {
            value: "saleUnpaid",
            text: this.$t('seller.products.options_sales.text_755'),
          },
          {
            value: "saleWaitingPayment",
            text: this.$t('seller.products.options_sales.text_756'),
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: this.$t('seller.products.options_saleswaiting.text_757'),
          },
          {
            value: "BOLETO",
            text: this.$t('seller.products.options_saleswaiting.text_758'),
          },
          {
            value: "PIX",
            text: this.$t('seller.products.options_saleswaiting.text_759'),
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: this.$t('seller.products.options_salesrefused.text_760'),
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: this.$t('seller.products.options_salesrefused.text_761'),
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: this.$t('seller.products.options_salesrefused.text_762'),
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: this.$t('seller.products.options_salesrefused.text_763'),
          },
          {
            value: "INVALID_DATA",
            text: this.$t('seller.products.options_salesrefused.text_764'),
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: this.$t('seller.products.options_salesrefused.text_765'),
          },
          {
            value: "INVALID_CVV",
            text: this.$t('seller.products.options_salesrefused.text_766'),
          },
          {
            value: "BANK",
            text: this.$t('seller.products.options_salesrefused.text_767'),
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: this.$t('seller.products.options_salesrefused.text_768'),
          },
          {
            value: "INVALID_INSTALLMENTS",
            text: this.$t('seller.products.options_salesrefused.text_769'),
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: this.$t('seller.products.options_salesrefused.text_770'),
          },
          {
            value: "SUSPECTED_FRAUD",
            text: this.$t('seller.products.options_salesrefused.text_771'),
          },
          {
            value: "GENERIC",
            text: this.$t('seller.products.options_salesrefused.text_772'),
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: this.$t('seller.products.options_sub.text_773'),
          },
          {
            value: "contractPaid",
            text: this.$t('seller.products.options_sub.text_774'),
          },
          {
            value: "contractTrialing",
            text: this.$t('seller.products.options_sub.text_775'),
          },
          {
            value: "contractPendingPayment",
            text: this.$t('seller.products.options_sub.text_776'),
          },
          {
            value: "contractEnded",
            text: this.$t('seller.products.options_sub.text_777'),
          },
          {
            value: "contractCanceled",
            text: this.$t('seller.products.options_sub.text_778'),
          },
        ],
        options_checkout: [
          {
            value: "checkoutAbandoned",
            text: this.$t('seller.products.options_checkout.text_779'),
          }
        ]
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Conexoes,
    Multiselect,
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    changeEditMeta(i) {
      this.editMeta = true;
      this.metaIsEdited = true;
      this.editMetaItem = i;
      let item = i;
      item.value = JSON.parse(i.value);
      this.payload = i.value;
    },
    filterItem(id, type) {
      let myArray = this.optionsConection;
      return myArray.filter((x) => x.id === id);
    },
    selectItem(id) {
      this.selectedConection = id;
      this.lista_options = [];
      this.getProductMeta();
    },
    removeConection(id) {
      this.loading = true;
      serviceConnection
        .destroy(id)
        .then(() => {
          this.loading = false;
          this.fetchConnection();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetInputs() {
      this.infusionsoft = [];
      this.sub_trigger2 = [];
      this.connection_name = null;
      this.connection_api_key = null;
      this.connection_base_url = null;
      this.lista_options = [];
      this.tag_options = [];
      this.tag = null;
      this.metas = [];
      this.editMeta = false;
      this.editMetaItem = null;
      this.openIntFrame = false;
      this.selectEvent = [];
      this.selectEventRefunsed = [];
      this.selectEventWaiting = [];
      this.select_callback = "voxuy";
      this.trigger = [];
      this.lista = null;
      this.gerenciar = false;
    },
    createVoxuy() {
      this.loading = true;
      serviceConnection
        .create({ ...this.payload, type: "voxuy" })
        .then((resp) => {
          this.submit = false;
          this.loading = false;
          this.newConnection = false;
          this.payload = {};
          this.fetchConnection();
          gtagSend('integracao_adicionada_produto', {
              ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
              ID_produto: this.id_product,
              titulo_do_produto:this.$store.getters.productName,
              tipo_de_integração: 'voxuy'
          });
          this.$bvToast.toast(this.$t('seller.products.voxuy.text_735'), {
            title: "Voxuy",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getProductMeta() {
      this.loading_meta = true;
      let data = {
        id: `${this.id_product}/meta`,
      };

      this.metas = [];

      productionService
        .read(data)
        .then((response) => {
          if (response.length) {
            this.editMeta = false;
            this.metaIsEdited = false;
            response.forEach((element) => {
              if (element.key == "product_in_voxuy") {
                this.metas = [element];
              }
            });
          } else {
            this.metas = [];
            this.editMeta = true;
            this.metaIsEdited = true;
          }
        })
        .finally(() => {
          this.loading_meta = false;
        });
    },
    createProductMeta() {
      this.loading = true;
      let data = this.payload;

      if(this.metas.length && this.editMetaItem === null){
        return
      }

      if (this.editMetaItem === null) {
        productionService
          .create(
            {
              key: "product_in_voxuy",
              value: JSON.stringify({
                name: this.payload.name,
                plan_id: this.payload.plan_id,
              }),
            },
            `${this.id_product}/meta`
          )
          .then(() => {
            this.$bvToast.toast(this.$t('seller.products.voxuy.text_736'), {
              title: "Meta",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.editMeta = false;
            this.metaIsEdited = false;
            this.editMetaItem = null;
          })
          .finally(() => {
            this.loading_meta = false;
          });
      } else {
        productionService
          .update(
            { ...this.editMetaItem, value: JSON.stringify(data) },
            `${this.id_product}/meta/${this.editMetaItem.id}`
          )
          .then(() => {
            this.$bvToast.toast(this.$t('seller.products.voxuy.text_737'), {
              title: "Meta",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.editMeta = false;
            this.editMetaItem = null;
          })
          .finally(() => {
            this.loading_meta = false;
          });
      }
    },
    // adicionar uma nova callback se o meta já estiver sido criado ou atializado
    async createCallback() {
      await this.createProductMeta();

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          let data = {
            product_id: this.id_product,
            trigger: this.connection_api_key,
            type: "voxuy",
            callback: "voxuy",
            connection_id: this.selectedConection,
          };

          if (this.trigger.length) {
            this.trigger.forEach(async (event) => {
              delete data.trigger;
              data.trigger = event;
              if (event === "saleRefused") {
                let sub_trigger_check1 = false;

                this.selectEventRefunsed.map(async (item, index) => {
                  if (item && !this.selectEventRefunsed[0]) {
                    sub_trigger_check1 = true;
                    await this.postCallback(
                      {
                        ...data,
                        trigger: "saleRefused",
                        sub_trigger:
                          this.events.options_salesRefused[index].value,
                      },
                      this.events.options_salesRefused[index].text
                    );
                  }
                });
                if (!sub_trigger_check1) {
                  await this.postCallback(
                    { ...data, trigger: "saleRefused" },
                    "saleRefused"
                  );
                }
              } else if (event === "saleWaitingPayment") {
                let sub_trigger_check2 = false;

                this.selectEventWaiting.map(async (item, index) => {
                  if (item && !this.selectEventWaiting[0]) {
                    sub_trigger_check2 = true;
                    await this.postCallback(
                      {
                        ...data,
                        trigger: "saleWaitingPayment",
                        sub_trigger:
                          this.events.options_salesWaiting[index].value,
                      },
                      this.events.options_salesWaiting[index].text
                    );
                  }
                });

                if (!sub_trigger_check2) {
                  await this.postCallback(
                    { ...data, trigger: "saleWaitingPayment" },
                    "saleWaitingPayment"
                  );
                }
              } else {
                await this.postCallback({ ...data, trigger: event }, event);
              }
            });
          } else {
            // valida se há algum evento selecionado
            this.loading = false;
            this.$bvToast.toast(`Selecione pelo menos um evento`, {
              title: "Voxuy",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        }
      });
    },
    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${this.convertTrigger(event)}] ${this.$t('seller.products.voxuy.text_739')}`,
            {
              title: "Voxuy",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide("modal-voxuy");
          this.resetInputs();
          setTimeout(() => {
            this.$emit("close");
          }, 500);
          EventBus.$emit("reloadVoxuyIntegration");
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // checkbox dos eventos
    triggerChanged(v) {
      if (this.trigger.includes(v)) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "saleRefused") {
        const include = this.events.sub_trigger.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventRefunsed = [];
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      } else {
        const include = this.events.sub_trigger2.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventWaiting = [];
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger2.splice(
            this.events.sub_trigger2.indexOf(v),
            1
          );
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    customLabelTags({ tag }) {
      return `${tag}`;
    },
    customLabel({ name }) {
      return `${name}`;
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.fetchLists(this.lista_options_next);
      }
    },
    fetchLists(page) {
      let data = {
        url: "/tags",
        connection_id: this.selectedConection,
        type: "infusionsoft",
        limit: 6,
      };

      if (page && page !== "first") {
        data.page = page;
      }

      infusionsoftService
        .search(data)
        .then((response) => {
          if (page && page !== "first") {
            this.lista_options.push(response.tags);
          } else {
            this.lista_options = response.tags;
          }

          this.lista_options_next = response.nextPageToken;
          this.lista_options_prev = response.prevPageToken;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchConnection() {
      this.optionsConection = [];
      serviceConnection
        .search({ connection_id: this.selectedConection, type: 'voxuy' })
        .then((response) => {
          response.forEach((element) => {
            if (element.type === "voxuy") {
              this.optionsConection.push({
                id: element.id,
                title: element.title,
                created_at: element.created_at,
              });
            }
          });
          this.infusionsoft = this.optionsConection;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do voxuy
      this.fetchConnection();
    },
    closeModal() {
      clearInterval(this.intervalid1);
      this.selectedConection = null;
      this.resetInputs();
      this.optionsConection = [{ id: null, name: this.$t('seller.products.voxuy.text_734') }];
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid": return this.$t('seller.products.options_sales.text_751')
        case "saleRefused": return this.$t('seller.products.options_sales.text_752')
        case "saleRefunded": return this.$t('seller.products.options_sales.text_753')
        case "BOLETO": return this.$t('seller.products.options_saleswaiting.text_758')
        case "PIX": return this.$t('seller.products.options_saleswaiting.text_759')
        case "saleChargedback": return this.$t('seller.products.options_sales.text_754')
        case "saleUnpaid": return this.$t('seller.products.options_sales.text_755')
        case "saleWaitingPayment": return this.$t('seller.products.options_sales.text_756')

        //  assinaturas
        case "contractUnpaid": return this.$t('seller.products.options_sub.text_773')
        case "contractPaid": return this.$t('seller.products.options_sub.text_774')
        case "contractTrialing": return this.$t('seller.products.options_sub.text_775')
        case "contractPendingPayment": return this.$t('seller.products.options_sub.text_776')
        case "contractEnded": return this.$t('seller.products.options_sub.text_777')

        //  checkout
        case "checkoutAbandoned": return this.$t('seller.products.options_checkout.text_779')

        //  venda recusada
        case "INVALID_CREDIT_CARD": return this.$t('seller.products.options_salesrefused.text_761')
        case "INSUFFICIENT_FUNDS": return this.$t('seller.products.options_salesrefused.text_762')
        case "INVALID_PAYMENT_TYPE": return this.$t('seller.products.options_salesrefused.text_763')
        case "INVALID_DATA": return this.$t('seller.products.options_salesrefused.text_764')
        case "INVALID_CLIENT_DATA": return this.$t('seller.products.options_salesrefused.text_765')
        case "INVALID_CVV": return this.$t('seller.products.options_salesrefused.text_766')
        case "BANK": return this.$t('seller.products.options_salesrefused.text_767')
        case "CREDIT_CARD_OPERATOR": return this.$t('seller.products.options_salesrefused.text_768')
        case "INVALID_INSTALLMENTS": return this.$t('seller.products.options_salesrefused.text_769')
        case "CURRENCY_NOT_SUPPORTED": return this.$t('seller.products.options_salesrefused.text_770')
        case "SUSPECTED_FRAUD": return this.$t('seller.products.options_salesrefused.text_771')
        case "GENERIC": return this.$t('seller.products.options_salesrefused.text_772')
        default: return trigger
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.infusion-btn {
  width: 200px;
  height: 42px;
  cursor: pointer;
  background-color: #148d24;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.infusion-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.infusion-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.infusion-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.infusion-btn .btn-text b {
  color: #fff !important;
}
.infusion-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.infusion-btn:active {
  background: #1669f2;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box{
  margin-left: 35px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top a {
  font-size: 14px;
  color: #81858e;
}
.lista-entrega h5 {
  color: #2133d2;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li,
  .opcoes-entrega li {
    display: block;
    height: 100px;
  }
  .acoes-webhook {
    margin-left: 30px;
    margin-top: 7px;
  }
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img.icon-file {
  width: 18px;
  margin-right: 30px;
}
.lista-entrega ul li div img + img {
  margin-left: 20px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p,
.info-lista {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 300px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}

.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li:hover,
  .opcoes-entrega li:hover {
    font-weight: 500;
    background: #f7f7f7;
  }
}

.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}

/* edição web */
.info-list {
  position: relative;
}
.info-list span {
  font-size: 14px;
}
.info-list p.ativo {
  text-transform: initial;
  font-size: 12px;
  margin-top: 3px;
  color: #81858e;
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 400px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid #81858e !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 667px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}

/* 
 */
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
  /* padding: 10px; */
}
.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.info-qtd {
  margin-top: -15px;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>

<template>
  <section class="current">
    <p class="title">{{ $t("shared.text_2031") }}</p>
    <p class="type" @click="$router.push('/novo-produto')">
      {{ $t(`views.seller.create_product.${$route.params.type || ""}`) }}
    </p>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.current {
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f7f7f7;

  p {
    text-align: center;
  }

  .title {
    color: #81858e;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .type {
    color: #333;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    cursor: pointer;
  }
}
</style>
import Rest from '@/services/Rest';

/**
 * @typedef {LeadLovers}
 */
export default class LeadLovers extends Rest {
  /**
   * @type {String}
   */
  static resource = '/leadlovers'
}
import { render, staticRenderFns } from "./leadLovers.vue?vue&type=template&id=338a935e&scoped=true"
import script from "./leadLovers.vue?vue&type=script&lang=js"
export * from "./leadLovers.vue?vue&type=script&lang=js"
import style0 from "./leadLovers.vue?vue&type=style&index=0&id=338a935e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338a935e",
  null
  
)

export default component.exports
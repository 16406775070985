<template>
  <list-item v-if="!loading" title="MemberKit" :list="list" @delete="removeMemberkit"/>
  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import Vue from "vue";

// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";

// helper:
import { EventBus } from "@/main.js";

// services:
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      showList: true,
      regras: [],
      list: [],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  methods: {
    async fetchMemberkitList() {
      this.loading = true;
      let data = {
        product_id: this.id_product,
        type: "memberkit",
        url: "list",
      };
      await serviceIntegration
        .search(data)
        .then((response) => {
          this.regras = response;
          this.resolveRulesList();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resolveRulesList() {
      this.list = [];
      [...this.regras]
        .reverse()
        .filter((item) => item.type === "memberkit")
        .map((item) => {
          if (item.data.access == "subscription") {
            this.list.push({
              id: item.id,
              icon: "memberkit-icon.svg",
              name: item.data.membership_name,
              connection_id: item.data.connection_id,
              access: item.data.access,
              actions: ["delete"],
            });
          } else {
            item.classroom.forEach((turma) => {
              this.list.push({
                id: item.id,
                icon: "memberkit-icon.svg",
                name: `#${item.id} - ${turma.name}`,
                connection_id: item.data.connection_id,
                access: item.data.access,
                actions: ["delete"],
              });
            });
          }
        });
    },
    async removeMemberkit(payload) {
      const { id, name } = payload;
      let texto = "";
      if (name) {
        if (name.indexOf("|") > -1) {
          texto = `Ao remover essa integração, as turmas vinculadas a #${id} também serão removidas`;
        } else {
          texto = "Deseja continuar com a Remoção dessa Assinatura?";
        }
      } else texto = "Deseja continuar com a Remoção desse item?";

      Vue.swal({
        title: "Remover entrega ",
        text: texto,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id: id,
          };

          await serviceIntegration
            .destroy(data)
            .then(async(response) => {
              if (response.success) {
                this.$bvToast.toast("Integração removida com sucesso!", {
                  title: "Memberkit",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                await this.fetchMemberkitList();
                EventBus.$emit("integracoesLista", -1);
              } else {
                this.$bvToast.toast("Houve um erro ao remover a Integração", {
                  title: "Memberkit",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  async mounted() {
    await this.fetchMemberkitList();
    EventBus.$on("reloadMemberKitContent", async () => {
      await this.fetchMemberkitList(); 
    });
  },
};
</script>
